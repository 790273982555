import React from "react";
import { useLayout } from "../contexts/layoutContext";

const MobileMapViewButton: React.FC = () => {
  const { isMobileMapView, setIsMobileMapView } = useLayout();
  return (
    <div className="md:hidden px-4 pb-4">
      <button
        onClick={() => setIsMobileMapView(isMobileMapView ? false : true)}
        className="bg-bj-purple-dark text-white px-4 py-2 rounded-full disabled:opacity-40"
      >
        {isMobileMapView ? "List view" : "Map view"}
      </button>
    </div>
  );
};

export default MobileMapViewButton;
