import React from "react";
import { useSearch } from "../contexts/searchContext";

const SmallHeadingDescription: React.FC = () => {
  const { search } = useSearch();
  return (
    <div className="px-4 md:px-10 mb-4 md:mb-6">
      <h1 className="heading !text-2xl md:text-3xl mb-2">{search.is_pseo ? search.computed_title : "Find venues 🌍"}</h1>
      <p>All venues on our platform are hand-picked for company retreats, team parties and offsites.</p>
    </div>
  );
};

export default SmallHeadingDescription;
