import React, { useState, useEffect, useRef, useCallback, ReactNode } from "react";
import { Formik, Form, useFormikContext } from "formik";
import Dates from "./sections/Dates/Dates";
import { BookingDate, Values } from "./EnquiryFormValues.interface";
import PrintValues from "./PrintValues";
import NumberPeople from "./sections/NumberPeople/NumberPeople";
import Budget from "./sections/Budget/Budget";
import PurposeOfRetreat from "./sections/PurposeOfRetreat/PurposeOfRetreat";
import OtherRequirements from "./sections/OtherRequirements/OtherRequirements";
import cx from "classnames";
import { useVenue } from "./contexts/venueContext";
import Button from "../Button";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useAuth, SignUp, SignUpButton, SignedOut, SignedIn, SignInButton } from "@clerk/clerk-react";
import { UseMutationResult, useMutation } from "@tanstack/react-query";
import useInitialValues from "./hooks/useInitialValues";
import useClickOutside from "./hooks/useClickOutside";
import MessageToVenue from "./sections/MessageToVenue/MessageToVenue";
import CompanyName from "./sections/CompanyName/CompanyName";

interface FormBodyProps {
  createEnquiryMutation: UseMutationResult<any, AxiosError, any>
}

const DisabledUntilDatesRoomsComplete: React.FC<{ children: ReactNode, className?: string, datesRoomsComplete: boolean }> = ({ children, className, datesRoomsComplete }) => {
  const { setFieldTouched, errors } = useFormikContext<Values>();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const disabledRef = useRef();

  useClickOutside(disabledRef, () => setIsPopoverOpen(false));

  const handleClickDisabledArea = useCallback(() => {
    if (datesRoomsComplete) return;
    if (!errors) return;
    console.log(errors);
    setIsPopoverOpen(true);
    if (typeof errors.booking_dates === "string") {
      setFieldTouched("booking_dates")
    }
    if (typeof errors.booking_dates === "object" && errors.booking_dates?.length) {
      (errors?.booking_dates as Array<Object>)?.forEach((datePair, index) => {
        setFieldTouched(`booking_dates.${index}.start_date`)
        setFieldTouched(`booking_dates.${index}.end_date`)
      });
    }
    setFieldTouched("number_people");
  }, [errors, setIsPopoverOpen, setFieldTouched, datesRoomsComplete]);

  return (
    <div ref={disabledRef} onClick={handleClickDisabledArea} className={className}>
      <div className={cx(datesRoomsComplete === false && "opacity-40 pointer-events-none")}>{children}</div>
      {isPopoverOpen && <div className="text-sm text-red-500 mt-2">Please complete the Dates and Rooms section before continuing.</div>}
    </div>
  )
}

const FormBody: React.FC<FormBodyProps> = ({ createEnquiryMutation }) => {
  const [datesRoomsComplete, setDatesRoomsComplete] = useState(false);
  const { values, errors, touched, setFieldTouched, submitCount } = useFormikContext<Values>();
  const { validationSchemas, last_enquiry, is_instant_connect } = useVenue();

  useEffect(() => {
    const datesRoomsValid = validationSchemas.datesRoomsSchema.isValidSync(values);
    setDatesRoomsComplete(datesRoomsValid)
  }, [values])

  useEffect(() => {
    if (last_enquiry) {
      (errors.booking_dates as Array<Object>)?.forEach((datePair, index) => {
        setFieldTouched(`booking_dates.${index}.start_date`)
        setFieldTouched(`booking_dates.${index}.end_date`)
      });
      setFieldTouched("number_people");
      setFieldTouched("budget_accommodation_amount");
    }
  }, [last_enquiry])

  return (
    <>
      <div className="bookings__panel">
        <SignedOut>
          <h2 className="bookings__heading mb-2">First, sign in or create an account 🔒</h2>
          <p className="mb-4">This takes a couple of clicks, and you can sign in with Google.</p>
          <div className="mb-4">
            <SignUpButton mode="modal"
              redirectUrl={window.location.pathname}
            >
              <Button>Create account</Button>
            </SignUpButton>
          </div>
          <div>
            <p>Already have an acount? <SignInButton mode="modal" redirectUrl={window.location.pathname}><a href="javascript:void(0)" className="text-bj-purple underline">Sign in.</a></SignInButton></p>
          </div>
        </SignedOut>
        <SignedIn>
          <h2 className="bookings__heading">Dates and rooms</h2>
          <div className="mb-4"><Dates /></div>
          <div className="mb-9"><NumberPeople /></div>
          <h2 className="bookings__heading">What is your budget?</h2>
          <DisabledUntilDatesRoomsComplete datesRoomsComplete={datesRoomsComplete} className="mb-9">
            <Budget />
          </DisabledUntilDatesRoomsComplete>
          <h2 className="bookings__heading">What is the purpose of your company retreat?</h2>
          <DisabledUntilDatesRoomsComplete datesRoomsComplete={datesRoomsComplete} className="mb-9">
            <PurposeOfRetreat />
          </DisabledUntilDatesRoomsComplete>
          <h2 className="bookings__heading">What is your company name?</h2>
          <DisabledUntilDatesRoomsComplete datesRoomsComplete={datesRoomsComplete} className="mb-9">
            <CompanyName />
          </DisabledUntilDatesRoomsComplete>
          <h2 className="bookings__heading">Other requirements</h2>
          <DisabledUntilDatesRoomsComplete datesRoomsComplete={datesRoomsComplete} className="mb-9">
            <OtherRequirements />
          </DisabledUntilDatesRoomsComplete>
          <h2 className="bookings__heading">Optional message to venue</h2>
          <DisabledUntilDatesRoomsComplete datesRoomsComplete={datesRoomsComplete} className="mb-4">
            <MessageToVenue />
          </DisabledUntilDatesRoomsComplete>
          <DisabledUntilDatesRoomsComplete datesRoomsComplete={datesRoomsComplete}>
            <div className="flex items-start">
              <div className="mr-2 text-xl">⚡️</div>
              <p className="mb-6">Your details will be saved and pre-filled for connecting with other venues.</p>
            </div>
            <Button loading={createEnquiryMutation.isLoading || createEnquiryMutation.isSuccess} type="submit">
              {is_instant_connect ? "Send message to venue" : "Send enquiry to venue"}
            </Button>
          </DisabledUntilDatesRoomsComplete>
          {
            submitCount > 0 && Object.keys(errors).length > 0 && (
              <div className="text-red-500 mt-4">Some details are invalid or missing, please check for error messages on each field above.</div>
            )
          }
          <div>{
            !createEnquiryMutation.isLoading && createEnquiryMutation.isError && (
              <div className="text-red-700 mt-4">
                <div className="mb-2">🙃 An error occurred when submitting your enquiry.</div>
                <div className="mb-2">Please check your details and try again. If this problem persists, please screenshot the entire form and email us at <strong>hello@basejam.com.</strong></div>
                <pre className="mb-1 text-sm">Error: {JSON.stringify(createEnquiryMutation.error.response?.data["message"])}</pre>
              </div>
            )}</div>
        </SignedIn>
      </div>
      {/* <PrintValues /> */}
      {/* <pre>
        {JSON.stringify({ minimum_budget_accommodation_prpn, minimum_budget_accommodation_pppn, other_requirements_checkboxes }, null, 2)}
      </pre> */}
      {/* <pre>datesRoomsComplete: {JSON.stringify(datesRoomsComplete)}</pre> */}
      {/* <pre className="text-sm">{JSON.stringify(last_enquiry, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors)}</pre> */}
      {/* <pre>{JSON.stringify(touched)}</pre> */}
    </>
  );
};

const EnquiryForm: React.FC = () => {
  const { validationSchemas, venue_slug } = useVenue();
  const { getToken } = useAuth();
  const initialValues = useInitialValues();
  const createEnquiryMutation = useMutation<any, AxiosError, any>(async (attrs) => {
    return axios.post(`/bookings`, {
      enquiry: attrs
    }, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getToken()}`
      }
    }
    );
  }, {
    onSuccess(data: AxiosResponse) {
      window["Turbo"].visit(`/bookings/${data.data.enquiry?.id}/sent`);
    },
  });
  return (
    <div>
      <Formik<Values>
        initialValues={initialValues}
        onSubmit={async (values) => {
          createEnquiryMutation.mutate({
            ...values,
            venue_slug
          });
        }}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={validationSchemas.topLevelSchema}
        validateOnMount={true}
        isInitialValid={false}
      >
        <Form>
          <FormBody createEnquiryMutation={createEnquiryMutation} />
        </Form>
      </Formik>
    </div>
  )
}

export default EnquiryForm;