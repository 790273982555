import React, { useEffect, useState } from "react";
import BJNumberInput from "../../../Shared/BJNumberInput/BJNumberInput";
import Toggle from "../../../Shared/Toggle/Toggle";
import { useFormikContext } from "formik";
import { Values } from "../../EnquiryFormValues.interface";
import RadioButtonGroup from "../../../Shared/RadioButtonGroup/RadioButtonGroup";
import * as Yup from "yup";

interface Props { }

const NumberPeople: React.FC<Props> = () => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<Values>();
  return (
    <div>
      <div className="mb-4">
        <label className="block mb-2 text-sm">Number of people</label>
        <div className="flex flex-col md:flex-row items-stretch md:items-center gap-4">
          <div className="w-full md:w-[172px]">
            <BJNumberInput
              label={null}
              number={values.number_people}
              onChange={(num) => setFieldValue("number_people", num)}
              onBlur={() => setFieldTouched("number_people")}
            />
          </div>
        </div>
        <div className="text-sm text-red-500 mt-2">{
          (errors?.number_people
            && touched?.number_people
            && (errors.number_people))
        }</div>
      </div>
      <div>
        <RadioButtonGroup
          options={[{
            id: "single_occupancy",
            name: "Single occupancy",
            iconName: "person-icon"
          }, {
            id: "sharing_rooms",
            name: "Room sharing",
            iconName: "people-icon"
          }]}
          selectedOptionId={values.occupancy_type}
          onClickOption={option => setFieldValue("occupancy_type", option.id)}
        />
      </div>
    </div>
  )

}

export default NumberPeople;