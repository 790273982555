import ReactOnRails from 'react-on-rails';

import BrowseV2 from '../bundles/react/components/BrowseV2/App';
import EnquiryForm from '../bundles/react/components/EnquiryForm/App';
import SearchFormV3 from '../bundles/react/components/SearchFormV3';
import LogosSection from '../bundles/react/components/LogosSection/LogosSection';
import VenueReel from '../bundles/react/components/VenueReel/VenueReel';
import CurrencySwitcher from "../bundles/react/components/CurrencySwitcher/CurrencySwitcher";
import Megamenu from "../bundles/react/components/Navigation/Megamenu";
import RenderDefaultComponentsWithContext from "../bundles/react/components/RenderDefaultComponents/WithContext";
import RenderDefaultComponentsWithoutContext from "../bundles/react/components/RenderDefaultComponents/WithoutContext";

ReactOnRails.setOptions({ turbo: true })

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  BrowseV2,
  EnquiryForm,
  SearchFormV3,
  LogosSection,
  VenueReel,
  CurrencySwitcher,
  Megamenu,
  RenderDefaultComponentsWithContext,
  RenderDefaultComponentsWithoutContext
});
