import React, { useRef, useState, useContext, ReactNode } from "react";
import { useMediaQuery } from "usehooks-ts";

const LayoutContext = React.createContext(null);

export const LayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const venuesListMapSplitDiv = useRef<HTMLElement>(null);
  const mapContainerDiv = useRef<HTMLElement>(null);
  const filtersDiv = useRef<HTMLElement>(null);
  const [isMobileMapView, setIsMobileMapView] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        venuesListMapSplitDiv,
        isMobileMapView,
        setIsMobileMapView,
        mapContainerDiv,
        filtersDiv,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
