import React, { useState, useCallback } from "react";
import { NumericFormat, numericFormatter } from "react-number-format"

enum Currency { GBP = "GBP", EUR = "EUR", USD = "USD", CHF = "CHF" }

const CURRENCY_SYMBOLS: Record<keyof typeof Currency, string> = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  CHF: "₣",
}

const CURRENCY_THOUSANDS_SEPARATORS: Record<keyof typeof Currency, string> = {
  GBP: ",",
  EUR: " ",
  USD: ",",
  CHF: "’"
}

interface Props {
  amountValue: string;
  currencyValue?: string;
  onChangeAmount: (x: any) => void;
  onChangeCurrency: (x: string) => void;
  onBlur: (e: any) => void;
}

const CurrencyInput: React.FC<Props> = ({ amountValue, currencyValue, onChangeAmount, onBlur, onChangeCurrency }) => {
  return (
    <div>
      <div className="relative rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5">
          <span className="text-gray-500 sm:text-sm">{CURRENCY_SYMBOLS[currencyValue]}</span>
        </div>
        <NumericFormat
          value={amountValue}
          onValueChange={(values) => onChangeAmount(values.floatValue)}
          decimalSeparator="#"
          allowedDecimalSeparators={[]}
          thousandSeparator={CURRENCY_THOUSANDS_SEPARATORS[currencyValue]}
          type="text"
          name="price"
          id="price"
          className="block w-full rounded-full border-0 py-2 pl-9 pr-20 text-gray-900 ring-inset ring-1 box-border ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-sm leading-[22px]"
          onBlur={onBlur}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="currency" className="sr-only">
            Currency
          </label>
          <select
            id="currency"
            name="currency"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            onChange={e => onChangeCurrency(e.target.value)}
            value={currencyValue}
          >
            <option value="GBP">GBP</option>
            <option value="EUR">EUR</option>
            <option value="USD">USD</option>
            <option value="CHF">CHF</option>
          </select>
        </div>
      </div>
    </div>
  )
};

export default CurrencyInput;