import React from "react";
import { Venue } from "../types/Venue.interface";
import LocationIcon from "../../../../../../assets/svg/location-icon.svg";
import PeopleIcon from "../../../../../../assets/svg/people-icon.svg";

interface Props {
  venue: Venue;
}

const VenueSlide: React.FC<Props> = ({ venue }) => {
  return (
    <div className="bg-white rounded-xl flex shadow-lg">
      <a className="w-[30%] block" href={`/v/${venue.slug}`} target="_blank">
        <img
          src={venue.primary_photo_url}
          className="w-full h-full object-cover rounded-l-xl"
        />
      </a>
      <div className="flex-1 py-5 px-4">
        <h2 className="font-heading text-xs mb-2">
          <a href={`/v/${venue.slug}`} target="_blank">{venue.teaser_title}</a>
        </h2>
        <div className="flex flex-col space-y-1">
          <div className="text-gray-600 text-xs flex items-center">
            <LocationIcon
              width="15"
              height="13"
              fill={null}
              className="fill-gray-700 mr-1.5"
            />
            {venue.region}, {venue.country}
          </div>
          <div className="text-gray-600 text-xs flex items-center">
            <PeopleIcon
              width="15"
              height={null}
              fill={null}
              className="fill-gray-700 mr-1.5"
            />
            Up to {venue.max_capacity} people
          </div>
        </div>
      </div>
    </div>
  );
};

export default VenueSlide;
