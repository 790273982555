import React from "react";
import { useFormikContext } from "formik";
import { Values } from "../../EnquiryFormValues.interface";
import Markdown from "react-markdown";
import { useVenue } from "../../contexts/venueContext";

interface Props { }

const OtherRequirements: React.FC<Props> = () => {
  const { values, setFieldValue } = useFormikContext<Values>();
  const { other_requirements_checkboxes } = useVenue();
  return (
    <div>
      {Object.keys(other_requirements_checkboxes).map(checkboxId => (
        <div key={checkboxId} className="flex items-center mb-[10px] last:mb-0">
          <input
            id={checkboxId}
            name={checkboxId}
            type="checkbox"
            className="h-4 w-4 mr-2 rounded border-gray-300 text-bj-purple-dark focus:bj-purple-dark"
            onChange={(e) => setFieldValue(`other_requirements.${checkboxId}`, e.target.checked)}
            checked={values.other_requirements[checkboxId]}
          />
          <label htmlFor={checkboxId}><Markdown components={{
            p: "span",
            em(props) {
              const { node, ...rest } = props
              return <em className="text-bj-purple-dark not-italic" {...rest} />
            }
          }}>{other_requirements_checkboxes[checkboxId].description}</Markdown></label>
        </div>

      ))}
    </div>
  )

}

export default OtherRequirements;