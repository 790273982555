export default (value, decoder) => {
  let keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined,
  };
  if (value in keywords) {
    return keywords[value];
  }
  return decoder(value);
};