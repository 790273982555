import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import cx from "classnames";
import { FieldInputProps, useField, useFormikContext, ErrorMessage } from 'formik';

interface Option {
  id: string;
  name: string;
}

interface Props {
  name?: string;
  options: Option[];
  selected?: Option;
  setSelected: (x: Option) => void;
  onBlur?: (e: any) => any;
  className?: string;
  isFormik?: boolean;
}

const Select: React.FC<Props> = ({ name, isFormik, options, selected, setSelected, onBlur, className }) => {
  let errors = {};
  let touched = {};
  let [field, meta, helpers]: [FieldInputProps<any>?, any?, any?] = [null, null, null]
  if (isFormik) {
    ({ errors, touched } = useFormikContext());
    ([field, meta, helpers] = useField(name));
    onBlur = field.onBlur;
  }
  return (
    <Listbox name={name} value={selected || ""} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className={cx("relative", className)}>
            <Listbox.Button
              onBlur={(e) => { console.log("onBlur"); !open && onBlur?.(e); }}
              className={cx(
                "relative w-full cursor-default rounded-full bg-white py-2 pl-5 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 text-sm leading-[22px]",
                isFormik && touched[name] && errors[name] && "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500",
              )}
            >
              <span className="block truncate">{selected?.name || "Choose..."}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => helpers?.setTouched?.(true)}
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      cx(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={option || ""}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={cx(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={cx(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            {isFormik && touched[name] && errors[name] && <div className="text-red-500 text-sm mt-1"><ErrorMessage name={name} /></div>}
          </div>
        </>
      )}
    </Listbox>
  )

}

export default Select;