import React from "react";
import MultiSelect from "../../MultiSelect";
import { Search } from "../types/Search.interface";
import { TagItem } from "../types/TagItem.interface";

interface Props {
  options: TagItem[];
  searchKey: string;
  search: Search;
  setSearch: (x: Search) => void;
  label: string;
  showAnything?: boolean;
}

const TagSelect: React.FC<Props> = ({
  options,
  searchKey,
  search,
  setSearch,
  label,
  showAnything = false
}) => (
  <MultiSelect
    label={label}
    options={options}
    selectedItems={
      search[searchKey]?.map((id) => options.find((tag) => tag.id === id)) || []
    }
    onChange={(items: TagItem[]) => {
      setSearch({
        ...search,
        [searchKey]: items.map((item) => item.id),
      });
    }}
    showAnything={showAnything}
  />
);

export default TagSelect;
