import React from "react";
import TagSelect from "../shared/TagSelect";
import { TagItem } from "../types/TagItem.interface";
import { useSearch } from "../contexts/searchContext";
import BJNumberInput from "../shared/BJNumberInput";
import CrossIcon from "../../../../../../assets/svg/cross.svg";
import { useLayout } from "../contexts/layoutContext";
import { Country } from "../types/Country.interface";
import CountrySelect from "../shared/CountrySelect";

interface Props {
  accommodation_styles: TagItem[];
  venue_amenities: TagItem[];
  retreat_styles: TagItem[];
  location_types: TagItem[];
  countries: Country[];
}

const Filters: React.FC<Props> = ({
  accommodation_styles,
  venue_amenities,
  retreat_styles,
  location_types,
  countries
}) => {
  const { search, setSearch, clearSearch } = useSearch();
  const { isMobileMapView, setIsMobileMapView } = useLayout();
  return (
    <div className="grid grid-cols-2 md:flex md:flex-wrap md:items-stretch gap-4 px-4 md:px-10 mb-4 md:mb-6">
      <div className="flex-0 lg:max-w-[280px]">
        <CountrySelect
          label="Country"
          selectedLocation={search.location}
          onChange={(location: any) => {
            setSearch({ ...search, location });
          }}
          countries={countries}
        />
      </div>
      <div className="flex-0 lg:max-w-[200px]">
        <TagSelect
          label="Location type"
          options={location_types}
          search={search}
          searchKey="location_type_ids"
          setSearch={setSearch}
        />
      </div>
      <div className="flex-0 lg:max-w-[200px]">
        <TagSelect
          label="Accommodation style"
          options={accommodation_styles}
          search={search}
          searchKey="accommodation_style_ids"
          setSearch={setSearch}
        />
      </div>
      <div className="flex-0 lg:max-w-[200px]">
        <TagSelect
          label="Amenities"
          options={venue_amenities}
          search={search}
          searchKey="venue_amenity_ids"
          setSearch={setSearch}
        />
      </div>
      <div className="flex-0 lg:max-w-[200px]">
        <TagSelect
          label="Reason for retreat"
          options={retreat_styles}
          search={search}
          searchKey="retreat_style_ids"
          setSearch={setSearch}
        />
      </div>
      <div className="flex-0 lg:max-w-[200px]">
        <BJNumberInput
          label="Number of people"
          number={search.number_rooms}
          onChange={(num) => setSearch({ ...search, number_rooms: num })}
        />
      </div>
      <div className="flex-0 md:min-w-[42px] flex flex-col">
        <div className="hidden md:block flex-1 w-full"></div>
        <div className="flex h-[38px] md:items-center pt-0">
          <button
            onClick={clearSearch}
            type="button"
            className="text-sm flex items-center text-gray-900"
          >
            <CrossIcon className="mr-2 w-5 h-5" />
            <span className="text-left">Clear filters</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
