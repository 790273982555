import React from "react";
import Select from "../../../Shared/Select/Select";
import { useFormikContext } from "formik";
import { Values } from "../../EnquiryFormValues.interface";

export const options = {
  team_connection: {
    id: "team_connection",
    name: "Team connection ✨"
  },
  hackathon: {
    id: "hackathon",
    name: "Hackathon 🏗️"
  },
  executive_leadership_retreat: {
    id: "executive_leadership_retreat",
    name: "Executive/leadership retreat 💼"
  },
  conference: {
    id: "conference",
    name: "Conference 💬"
  },
  other: {
    id: "other",
    name: "Other 💡"
  }
}

const PurposeOfRetreat: React.FC = () => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<Values>();
  return (
    <div>
      <div className="max-w-[280px]">
        <Select options={Object.values(options)} selected={options[values.purpose]} setSelected={option => {
          setFieldValue("purpose", option.id)
        }} onBlur={() => setFieldTouched("purpose")} />
      </div>
      {
        (errors?.purpose
          && touched?.purpose
          && <div className="text-sm text-red-500 mt-2">{errors.purpose}</div>)
      }
    </div>
  )

}

export default PurposeOfRetreat;