import React from "react";
import { Field, useFormikContext } from "formik";
import { Values } from "../../EnquiryFormValues.interface";

interface Props { }

const CompanyName: React.FC<Props> = () => {
  const { errors, touched } = useFormikContext<Values>();
  return (
    <div>
      <div className="max-w-[280px]">
        <Field name="company_name" className="rounded-full border-0 text-sm leading-[22px] py-2 px-5 ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-inset focus:ring-bj-purple-dark w-full" />
      </div>
      {
        (errors?.company_name
          && touched?.company_name
          && <div className="text-sm text-red-500 mt-2">{errors.company_name}</div>)
      }
    </div>
  )
}

export default CompanyName;