import React, { useEffect } from "react";
import { ClerkLoaded, SignInButton, SignUpButton, SignedIn, SignedOut, UserButton, useAuth } from "@clerk/clerk-react";
import { Clerk } from "@clerk/types"
import Button from "../Button";
import posthog from "posthog-js";
import qs from "qs";
import qsBooleanDecoder from "~/bundles/utils/qsBooleanDecoder";

declare global {
  interface Window {
    Clerk: Clerk;
    posthog: typeof posthog;
    dataLayer?: any[];
    fbq?: any;
  }
}

const newAccountRedirectURL = () => {
  const existingQS = qs.parse(window.location.search.slice(1), { decoder: qsBooleanDecoder });
  const newQS = qs.stringify({ ...existingQS, new_account: true });
  return `${window.location.origin}${window.location.pathname}?${newQS}`;
}

const HeaderAuth: React.FC = () => {
  return (
    <div className="hidden md:flex justify-end items-center ml-16">
      <ClerkLoaded>
        <SignedIn>
          <UserButton afterSignOutUrl={window.location.href} />
        </SignedIn>
        <SignedOut>
          <SignInButton mode="modal" redirectUrl={`/auth-redirect?url=${window.btoa(window.location.href)}`}>
            <Button variant="outline" className="text-base px-4 h-[34px] mr-4">Sign in</Button>
          </SignInButton>
          <SignUpButton mode="modal" redirectUrl={`/auth-redirect?url=${window.btoa(window.location.href)}`}>
            <Button variant="default" className="text-base px-4 h-[34px] mr-4">Create account</Button>
          </SignUpButton>
        </SignedOut>
      </ClerkLoaded>
    </div>
  )
}

export default HeaderAuth;