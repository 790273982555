import React, { useRef, useState, useContext, ReactNode } from "react";
import buildValidationSchema from "../buildValidationSchema";
import { AnyObjectSchema } from "yup";
import Props from "../Props.interface";

export interface VenueInfo extends Omit<Props, "children"> {
  validationSchemas: {
    bookingDatesSchema: AnyObjectSchema,
    datesRoomsSchema: AnyObjectSchema,
    topLevelSchema: any
  }
}

const VenueContext = React.createContext<VenueInfo>(null);

export const VenueProvider: React.FC<Props & { children: ReactNode }> = ({
  children, ...props
}) => {

  const validationSchemas = buildValidationSchema(props)

  return (
    <VenueContext.Provider
      value={{
        ...props,
        validationSchemas
      }}
    >
      {children}
    </VenueContext.Provider>
  );
};

export const useVenue = () => useContext(VenueContext);
