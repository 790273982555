import React from "react";

export interface ReelVenue {
  id: string;
  slug: string;
  primary_photo_url: string;
  location_text: string;
  exclusive_hire_only: boolean;
  from_price_prpn?: {
    GBP: number;
    EUR: number;
    USD: number;
    CHF: number;
  }
  from_price_exclusive_hire?: {
    GBP: number;
    EUR: number;
    USD: number;
    CHF: number;
  }
}

interface Props {
  venue: ReelVenue;
}

const VenueReelCell: React.FC<Props> = ({ venue }) => {
  return (
    <a href={`/v/${venue.slug}`} className="block h-full relative w-[430px] lg:w-[570px] mx-7">
      <div
        className="
          bg-white
          absolute
          top-2
          left-2
          md:top-[14px]
          md:left-[14px]
          rounded-lg
          px-2
          md:px-[14px]
          py-0.5
          md:py-1
          text-xs
          md:text-sm
        "

      >{venue.location_text}</div>
      <div className="absolute bottom-2 right-2 md:bottom-[14px] md:right-[14px] flex flex-col items-end">
        {/* {venue.exclusive_hire_only && <div className="bg-light-grey-purple font-medium text-bj-purple-dark rounded-lg text-xs leading-5 px-2 inline-block mb-2">Exclusive hire only</div>} */}
        {/* <a href={`/v/${venue.slug}`} target="_blank" className="inline-block bg-light-grey-purple text-bj-purple-dark shadow-md px-4 py-0.5 rounded-lg font-bold mb-2">See venue &rarr;</a> */}
        <div className="bg-light-grey-purple shadow-md rounded-lg px-5 py-1.5 text-bj-purple-dark text-sm">
          {venue.exclusive_hire_only && venue.from_price_exclusive_hire ?
            <>from <span className="inline-block font-archivo font-extrabold text-lg">£{Math.floor(venue.from_price_exclusive_hire.GBP)}</span> / night, exclusive hire <span className="font-extrabold">&rarr;</span></>
            :
            <>from <span className="inline-block font-archivo font-extrabold text-lg">£{Math.floor(venue.from_price_prpn.GBP)}</span> / room / night <span className="font-extrabold">&rarr;</span></>
          }
        </div>
      </div>
      <img src={venue.primary_photo_url} className="w-full h-full object-cover rounded-[11px]" />
    </a>

  )

}

export default VenueReelCell