import React from "react";
import { Search } from "./types/Search.interface";
import Filters from "./sections/Filters";
import VenueList from "./sections/VenueList";
import { useSearch } from "./contexts/searchContext";
import { LockedVenue, Venue } from "./types/Venue.interface";
import { TagItem } from "./types/TagItem.interface";
import { useIsClient, useMediaQuery } from "usehooks-ts";
import { useLayout } from "./contexts/layoutContext";
import HeadingDescription from "./sections/HeadingDescription";
import MobileMapViewButton from "./sections/MobileMapViewButton";
import cx from "classnames";
import MapContainer from "./sections/MapContainer";
import SmallHeadingDescription from "./sections/SmallHeadingDescription";
import { Country } from "./types/Country.interface";

export interface Props {
  search_params: Search;
  initial_venues: Venue[];
  initial_locked_venues: LockedVenue[];
  accommodation_styles: TagItem[];
  venue_amenities: TagItem[];
  retreat_styles: TagItem[];
  location_types: TagItem[];
  countries: Country[];
}

const BrowsePage: React.FC<Props> = ({
  search_params,
  accommodation_styles,
  venue_amenities,
  retreat_styles,
  location_types,
  countries
}) => {
  const {
    venuesListMapSplitDiv,
    filtersDiv,
    isMobileMapView,
  } = useLayout();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const isClient = useIsClient();
  const { search } = useSearch();

  return (
    <div>
      <div
        ref={filtersDiv}
        className="md:sticky md:top-[112px] md:w-full md:z-10 bg-white border-b border-gray-300 pt-4 md:pt-0"
      >
        {!search.is_pseo && <SmallHeadingDescription />}
        <Filters
          accommodation_styles={accommodation_styles}
          venue_amenities={venue_amenities}
          retreat_styles={retreat_styles}
          location_types={location_types}
          countries={countries}
        />
        <MobileMapViewButton />
      </div>
      <div
        ref={venuesListMapSplitDiv}
        className="md:flex md:pl-10 pt-0"
        suppressHydrationWarning
      >
        <div
          className={cx(
            !isDesktop && isMobileMapView ? "hidden" : "block",
            "md:w-1/2 space-y-10 md:space-y-8 py-9 px-4 md:pl-0 md:pr-16"
          )}
        >
          <VenueList />
        </div>
        {isClient && <MapContainer />}
      </div>
    </div>
  );
};

export default BrowsePage;
