import React, { RefObject, useCallback } from "react";
import { LockedVenue } from "../types/Venue.interface";
import LockIcon from "../../../../../../assets/svg/lock-icon.svg";

interface Props {
  venue: LockedVenue;
}

const LockedVenueCell = React.forwardRef(({ venue }: Props, ref: RefObject<HTMLDivElement>) => {
  const openSignUp = useCallback(() => {
    (window as any).openSignUp();
  }, []);

  return (
    <div ref={ref} className="md:flex md:space-x-7">
      <div className="block mb-4 md:mb-0 md:w-1/2 relative z-0">
        <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
          <LockIcon className="mb-4" width="22" />
          <button
            type="button"
            className="
              ring-1
              ring-white
              text-white
              rounded-full
              px-4
              py-2
              bg-white
              bg-opacity-20
              hover:bg-opacity-100
              hover:text-black
              transition-colors
              duration-100
              text-sm
              xl:text-base
            "
            onClick={openSignUp}
          >
            Create free account to view
          </button>
        </div>
        <img
          src={venue.primary_photo_url}
          className="rounded-md brightness-50 relative z-10"
        />
      </div>
      <div className="flex-1 pr-8 md:pr-0">
        <div className="bg-gray-200 h-7 mb-2"></div>
        <div className="bg-gray-200 h-4 mb-2"></div>
        <div className="bg-gray-200 h-4 mb-2"></div>
        <div className="bg-gray-200 h-4 mb-4"></div>
        <div className="flex space-x-4 mb-2">
          <div className="bg-gray-200 flex-1 h-4"></div>
          <div className="bg-gray-200 flex-1 h-4"></div>
        </div>
      </div>
    </div>
  );
});

export default LockedVenueCell;
