import React, { useState, useCallback, useRef, useEffect } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { TagItem } from "./BrowseV2/types/TagItem.interface";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const truncate = (options, showAnything = false) => {
  if (!options || options === null) return "";
  if (options.length === 0) {
    return showAnything ? "Anything 🔍" : "";
  }
  if (options.length === 1) {
    return options[0].name;
  }
  if (options.length === 2) {
    return `${options[0].name}, ${options[1].name}`;
  }
  return `${options[0].name}, ${options[1].name} + ${options.length - 2}`;
};

interface Item {
  id: string;
  name: string;
}

interface Props {
  options: TagItem[];
  label: string;
  selectedItems: TagItem[];
  onChange: (x: TagItem[]) => void;
  showAnything?: boolean;
}

const MultiSelect: React.FC<Props> = ({
  options,
  selectedItems = [],
  label,
  onChange,
  showAnything = false
}) => {
  const [query, setQuery] = useState("");
  const rootEl = useRef(null);

  // const handleOnChange = useCallback((items) => {
  //   console.log("%%% onChange", items);
  //   onChange(items);
  // });

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
        return option.name.toLowerCase().includes(query.toLowerCase());
      });

  return (
    // @ts-ignore
    <Combobox as="div" value={selectedItems} onChange={onChange} multiple>
      <Combobox.Label className="block text-sm font-medium text-black">
        {label}
      </Combobox.Label>
      <div className="relative mt-1">
        {/* <div>{selectedOptions.map((option) => option.name).join(", ")}</div> */}
        <Combobox.Input
          className="w-full rounded-full border border-gray-300 bg-white py-2 pl-4 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event: any) => setQuery(event.target.value)}
          displayValue={(options) => truncate(options, showAnything)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md pl-2 pr-3 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredOptions.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.id}
                value={option}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-8 pr-4",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active }) => {
                  const selected = selectedItems.find(
                    (item) => item.id === option.id
                  );
                  return (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {option.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 left-0 flex items-center pl-1.5",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  );
                }}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default MultiSelect;
