import React from "react";
import Marquee from "react-fast-marquee";

import CanvaLogo from "../../../../../assets/svg/company-logos/canva-white.svg";
import NetflixLogo from "../../../../../assets/svg/company-logos/netflix-white.svg";
import WorkLifeLogo from "../../../../../assets/svg/company-logos/work-life-white.svg";
import VistaPrintLogo from "../../../../../assets/svg/company-logos/vistaprint-white.svg";
import FableAndManeLogo from "../../../../../assets/svg/company-logos/fable-and-mane-white.svg";
import BolLogo from "../../../../../assets/svg/company-logos/bol-white.svg";
import { useIsClient } from "usehooks-ts";

const LogosSection: React.FC = () => {
  const isClient = useIsClient();
  return (
    <section className="py-5 md:py-7 bg-basejam-gradient">
      {isClient
        ? <Marquee autoFill speed={16}>
          <CanvaLogo className="h-[34px] md:h-[45px] w-auto mx-4 md:mx-6" />
          <NetflixLogo className="h-[23px] md:h-[30px] w-auto mx-4 md:mx-6" />
          <WorkLifeLogo className="h-[15px] md:h-[20px] w-auto mx-4 md:mx-6" />
          <VistaPrintLogo className="h-[23px] md:h-[32px] w-auto mx-4 md:mx-6" />
          <FableAndManeLogo className="h-[13px] md:h-[17px] w-auto mx-4 md:mx-6" />
          <BolLogo className="h-[17px] md:h-[21px] w-auto mx-4 md:mx-6" />
        </Marquee>
        : <div className="w-[100vw] overflow-hidden">
          <div className="flex w-[200vw] relative left[-100vw]justify-center items-center">
            <CanvaLogo className="h-[34px] md:h-[45px] w-auto mx-4 md:mx-6" />
            <NetflixLogo className="h-[23px] md:h-[30px] w-auto mx-4 md:mx-6" />
            <WorkLifeLogo className="h-[15px] md:h-[20px] w-auto mx-4 md:mx-6" />
            <VistaPrintLogo className="h-[23px] md:h-[32px] w-auto mx-4 md:mx-6" />
            <FableAndManeLogo className="h-[13px] md:h-[17px] w-auto mx-4 md:mx-6" />
            <BolLogo className="h-[17px] md:h-[21px] w-auto mx-4 md:mx-6" />
            <CanvaLogo className="h-[34px] md:h-[45px] w-auto mx-4 md:mx-6" />
            <NetflixLogo className="h-[23px] md:h-[30px] w-auto mx-4 md:mx-6" />
            <WorkLifeLogo className="h-[15px] md:h-[20px] w-auto mx-4 md:mx-6" />
            <VistaPrintLogo className="h-[23px] md:h-[32px] w-auto mx-4 md:mx-6" />
            <FableAndManeLogo className="h-[13px] md:h-[17px] w-auto mx-4 md:mx-6" />
            <BolLogo className="h-[17px] md:h-[21px] w-auto mx-4 md:mx-6" />
          </div>
        </div>
      }
    </section>
  )
}

export default LogosSection;