import React, { useLayoutEffect, useRef } from "react";
import cx from "classnames";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useIsomorphicLayoutEffect } from "usehooks-ts";

interface Page {
  event_type: string;
  modifier: string;
  path: string;
  title: string;
  short_title: string;
  megamenu_title: string;
  megamenu_emoji: string;
}

interface Props {
  is_home?: boolean;
  pages: {
    company_retreat_venues: Page[];
    // leadership_retreat_venues: Page[];
    location_types: Page[];
  }
}

const PageLink: React.FC<{ page: Page }> = ({ page }) => (
  <li>
    <a href={page.path} className="flex items-start">
      <span className="mr-2">{page.megamenu_emoji}</span>
      <span dangerouslySetInnerHTML={{ __html: page.megamenu_title }}></span>
    </a>
  </li>
);

interface SetSizesProps {
  headerElement: HTMLElement;
  megamenuElement: HTMLDivElement;
  liElement: HTMLLIElement;
  megamenuNotch: HTMLDivElement;
}

// This function is run on window resize event
const setSizes = ({ headerElement, megamenuElement, liElement, megamenuNotch }: SetSizesProps) => {
  // <li> element is position: absolute
  // Positions are relative to <li> element
  // Position megamenu directly at the bottom of the li element
  megamenuElement.style.top = `${liElement.getBoundingClientRect().height + 15}px`;
  // Right position equal to right bound of max-w-std container
  megamenuElement.style.right = `-${headerElement.getBoundingClientRect().right - liElement.getBoundingClientRect().right}px`;

  // Left position is the highest value out of:
  // - left bound of max-w-std container
  // - right bound - 1100 pixels (so that megamenu has a max width of 1100)
  const mmMaxWidth = 733;
  // The existing width from left of the <li> element to the right bound
  const mmWidthToRightBound = headerElement.getBoundingClientRect().right - liElement.getBoundingClientRect().left;
  // Left position relative to <li> element to make the megamenu width 1100
  const leftPositionBasedOnMaxWidth = (mmMaxWidth - mmWidthToRightBound) * -1;
  // Left position relative to <li> element to set left position as left bound of max-w-std container
  const leftPositionBasedOnLeftBound = headerElement.getBoundingClientRect().left - liElement.getBoundingClientRect().left;

  let leftPosition;
  // Choose which one is the higher number and set on megamenu left position (both are negative)
  if (leftPositionBasedOnMaxWidth > leftPositionBasedOnLeftBound) {
    leftPosition = leftPositionBasedOnMaxWidth;
  } else {
    leftPosition = leftPositionBasedOnLeftBound;
  }
  megamenuElement.style.left = `${leftPosition}px`;

  // Notch
  megamenuNotch.style.top = `${liElement.getBoundingClientRect().height}px`;

}

const Megamenu: React.FC<Props> = ({ is_home, pages }) => {

  const megamenuElement = useRef<HTMLDivElement>();
  const liElement = useRef<HTMLLIElement>();
  const megamenuNotch = useRef<HTMLDivElement>();

  useIsomorphicLayoutEffect(() => {
    const headerElement = document.getElementById("header-mw-inner");
    setSizes({ headerElement, megamenuElement: megamenuElement.current, liElement: liElement.current, megamenuNotch: megamenuNotch.current });
    window["setMegamenuSizes"] = () => setSizes({ headerElement, megamenuElement: megamenuElement.current, liElement: liElement.current, megamenuNotch: megamenuNotch.current });
    window.addEventListener("resize", window["setMegamenuSizes"]);
    return () => window.removeEventListener("resize", window["setMegamenuSizes"]);
  }, [megamenuElement.current, liElement.current, megamenuNotch.current])

  return (
    <li className="block relative group" ref={liElement}>
      <a href="/venues" className={cx("flex items-center", !is_home && "text-base")}><span className="mr-1">Venues</span><ChevronDownIcon className="w-4" /></a>
      <div ref={megamenuNotch} className="absolute hidden group-hover:flex z-50 w-full items-stretch justify-center pt-[6px]">
        <div className="bg-white w-5 h-5 rotate-45 border border-b-transparent border-r-transparent border-t-bj-purple-light border-l-bj-purple-light"></div>
      </div>
      <div ref={megamenuElement} className="absolute hidden group-hover:block shadow-md rounded-[18px] border-bj-purple-light border bg-white z-40">
        <div className="px-8 py-6">
          <div className="grid grid-cols-2 lg:grid-cols-2 gap-8 text-base mb-3">
            <div>
              <h3 className="font-bold mb-2">Company retreat venues</h3>
              <ul className="space-y-2">
                {pages.company_retreat_venues.map(page => <PageLink key={page.path} page={page} />)}
              </ul>
            </div>
            {/* <div>
              <h3 className="font-bold mb-2">Leadership retreat venues</h3>
              <ul className="space-y-2">
                {pages.leadership_retreat_venues.map(page => <PageLink key={page.path} page={page} />)}
              </ul>
            </div> */}
            <div>
              <h3 className="font-bold mb-2">Location types</h3>
              <ul className="space-y-2">
                {pages.location_types.map(page => <PageLink key={page.path} page={page} />)}
              </ul>
            </div>
          </div>
          <div>
            <a href="/venues" className="font-bold text-base">View all venues &rarr;</a>
          </div>
        </div>
      </div>
    </li>
  )
}

export default Megamenu;