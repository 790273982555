import React from "react";
import { useIsClient, useMediaQuery } from "usehooks-ts";
const MAPBOX_TOKEN =
  "pk.eyJ1IjoiY2hyaXNiYXNlamFtIiwiYSI6ImNsYnFmMDdlbzA2M3Yzb250YzVldno0bzkifQ.gibpaaoITgP9aGxkOeY3fg";

import Map, { Marker } from "react-map-gl";
import { useVenues } from "../contexts/venuesContext";
import { useLayout } from "../contexts/layoutContext";
import { useSwiperRef } from "../contexts/swiperRefContext";

interface Props { }

const VenuesMap: React.FC<Props> = () => {
  const isClient = useIsClient();
  const { venues, locked_venues, venueRefs } = useVenues();
  const { swiper } = useSwiperRef();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const {
    filtersDiv,
  } = useLayout();

  const calculateTotalFixedOffset = () => {
    const header = document.querySelector("#header-announcement");
    const headerHeight = header.getBoundingClientRect().height;
    const filtersHeight = filtersDiv.current.getBoundingClientRect().height;
    return headerHeight + filtersHeight;
  };

  const slideToVenue = (venue) => {
    if (venueRefs.current.hasOwnProperty(venue.id)) {
      if (isDesktop) {
        const el = venueRefs.current[venue.id];
        const totalOffset = calculateTotalFixedOffset() + 20;
        console.log(totalOffset);
        window.scrollTo({
          top:
            el.getBoundingClientRect().top - window.scrollY * -1 - totalOffset,
          behavior: "smooth",
        });
      } else {
        const index = [...venues, ...locked_venues].findIndex(
          (v) => v.id === venue.id
        );
        swiper?.slideTo(index);
      }
    }
  };

  if (!isClient) {
    return null;
  }

  return (
    <Map
      id="venuesMap"
      // @ts-ignore
      initialViewState={{
        // @ts-ignore
        center: [-1, 51],
        zoom: 2,
      }}
      style={{ width: "100%", height: "100%" }}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      mapboxAccessToken={MAPBOX_TOKEN}
    >
      {[...venues, ...locked_venues].map((venue) => (
        <Marker
          key={venue.id}
          longitude={venue.longitude}
          latitude={venue.latitude}
          color="#4A00E0"
          onClick={() => slideToVenue(venue)}
        />
      ))}
    </Map>
  );
};

export default VenuesMap;
