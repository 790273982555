import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import cx from "classnames";

export interface Currency {
  threeLetterSymbol: string;
  emoji: string;
}

interface Props {
  currencies: Currency[]
  selectedCurrency: Currency;
  onChangeCurrency: (x: Currency) => void;
}

const CurrencyDropdown: React.FC<Props> = ({ currencies, selectedCurrency, onChangeCurrency }) => {
  return (
    <Listbox value={selectedCurrency} onChange={onChangeCurrency}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change published status</Listbox.Label>
          <div className="relative">
            <div className="inline-flex divide-x divide-gray-200 rounded-md shadow-sm ring-1 ring-gray-200">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-gray-900 shadow-sm">
                <p className="text-sm font-semibold">{selectedCurrency.emoji}&nbsp;{selectedCurrency.threeLetterSymbol}</p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-white p-2 hover:bg-gray-200 focus:outline-none">
                <span className="sr-only">Switch currency</span>
                <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-50 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {currencies.map((option) => (
                  <Listbox.Option
                    key={option.threeLetterSymbol}
                    className={({ active }) =>
                      cx(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.threeLetterSymbol}</p>
                          {selected ? (
                            <span className={active ? 'text-white' : 'text-indigo-600'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        {/* <p className={cx(active ? 'text-indigo-200' : 'text-gray-500', 'mt-2')}>
                          {option.description}
                        </p> */}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default CurrencyDropdown;
