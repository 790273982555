import React, { RefObject } from "react";
import { Venue } from "../types/Venue.interface";
import LocationIcon from "../../../../../../assets/svg/location-icon.svg";
import PeopleIcon from "../../../../../../assets/svg/people-icon.svg";
import BedIcon from "../../../../../../assets/svg/bed-icon.svg";
import LightningBolt from "../../../../../../assets/svg/lightning-bolt.svg";

interface Props {
  venue: Venue;
}

const VenueCell = React.forwardRef(({ venue }: Props, ref: RefObject<HTMLDivElement>) => (
  <div ref={ref} className="md:flex md:space-x-7">
    <a href={`/v/${venue.slug}`} target="_blank" className="block mb-4 md:mb-0 md:w-1/2 relative">
      {venue.is_instant_connect && <div className="absolute top-[10px] left-[10px] bg-white bg-opacity-30 rounded-full text-xs leading-[24px] pl-2 pr-[10px] flex items-center"><LightningBolt className="w-[16px] mr-1" />Instant connect</div>}
      <img src={venue.primary_photo_url} className="rounded-md" onContextMenu={(e) => e.preventDefault()} />
    </a>
    <div className="flex-1 pr-8 md:pr-0">
      <h2 className="font-heading text-lg leading-tight mb-3">
        <a href={`/v/${venue.slug}`} target="_blank">{venue.teaser_title}</a>
      </h2>
      <p className="text-gray-600 text-sm mb-3">
        {venue.short_description}
      </p>
      <div className="flex flex-wrap gap-x-4 mb-2">
        <div className="text-gray-600 text-sm mb-2 flex items-center">
          <LocationIcon
            width="10"
            height={null}
            fill={null}
            className="fill-gray-700 mr-1.5"
          />
          {venue.region}, {venue.country}
        </div>
        <div className="text-gray-600 text-sm mb-2 flex items-center">
          <PeopleIcon
            width="15"
            height={null}
            fill={null}
            className="fill-gray-700 mr-1.5"
          />
          Up to {venue.computed_total_number_beds} people
        </div>
        <div className="text-gray-600 text-sm mb-2 flex items-center">
          <BedIcon
            width="15"
            height={null}
            fill={null}
            className="fill-gray-700 mr-1.5"
          />
          {venue.computed_total_number_bedrooms} bedrooms
        </div>
      </div>
      {/* <div>
        <pre>
          {JSON.stringify(venue.minimum_budget_accommodation_prpn)}
          {JSON.stringify(venue.minimum_budget_exclusive_hire_per_night)}
        </pre>
      </div> */}
      <div>
        <div className="bg-light-grey-purple rounded-lg text-bj-purple-dark px-5 py-1 inline-flex">
          {venue.minimum_budget_accommodation_prpn
            ? <div className="text-sm">from&nbsp;&nbsp;<span className="font-archivo text-lg font-extrabold">{venue.minimum_budget_accommodation_prpn[venue.pricing_currency]}</span>&nbsp;&nbsp;/ room / night</div>
            : <div className="text-sm">from&nbsp;&nbsp;<span className="font-archivo text-lg font-extrabold">{venue.minimum_budget_exclusive_hire_per_night[venue.pricing_currency]}</span>&nbsp;&nbsp;/ night, exclusive hire</div>
          }
        </div>
      </div>

      {/* <div className="flex items-center flex-wrap gap-2">
        {venue.accommodation_styles.map((style) => (
          <span key={style.name} className="bg-gray-100 px-1 py-0.5 rounded text-gray-800 text-xs whitespace-nowrap">
            {style.name}
          </span>
        ))}
      </div> */}
    </div>
  </div>
));

export default VenueCell;