import React from "react";
import CurrencyInput from "../../../Shared/CurrencyInput/CurrencyInput";
import { useFormikContext } from "formik";
import { Values } from "../../EnquiryFormValues.interface";
import Select from "../../../Shared/Select/Select";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import InformationIcon from "../../../../../../../assets/svg/information-icon.svg";
import { VenueInfo, useVenue } from "../../contexts/venueContext";
import cx from "classnames";

const options = {
  pppn: {
    id: "pppn",
    name: "Per person, per night"
  },
  everyone_per_night: {
    id: "everyone_per_night",
    name: "Everyone, per night"
  },
  everyone_full_stay: {
    id: "everyone_full_stay",
    name: "Everyone, for full stay"
  }
}

interface Props { }

const CURRENCY_SYMBOLS: Record<string, string> = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  CHF: "₣",
}

const CURRENCY_LOCALE_STRING: Record<string, string> = {
  GBP: "en-GB",
  EUR: "fr-FR",
  USD: "en-US",
  CHF: "de-CH",
}

const budgetTooLowText = (errorCode: string, values: Values, venueInfo: VenueInfo) => {

  if (!errorCode.startsWith("min--")) { return <span>{errorCode}</span> }

  const startDate = values.booking_dates[0].start_date.toDate();
  const endDate = values.booking_dates[0].end_date.toDate();
  const numberNights = differenceInCalendarDays(endDate, startDate)
  const roomPerson = values.occupancy_type === "sharing_rooms" ? "person" : "room";
  const roomsPrices = values.occupancy_type === "sharing_rooms" ? "Prices" : "Rooms";

  if (venueInfo.exclusive_hire_only) {
    const minimumPPPN = venueInfo.minimum_budget_exclusive_hire_per_night[values.budget_accommodation_currency] / values.number_people;
    const minimumFullStay = venueInfo.minimum_budget_exclusive_hire_per_night[values.budget_accommodation_currency] * numberNights;

    switch (errorCode) {
      case "min--pppn":
        return <span>The minimum exclusive hire price at this venue is <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(venueInfo.minimum_budget_exclusive_hire_per_night[values.budget_accommodation_currency]).toLocaleString(CURRENCY_LOCALE_STRING[values.budget_accommodation_currency])}</strong> per night.
          For your group size, you'd need a minimum budget per person per night of <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(minimumPPPN).toLocaleString(CURRENCY_LOCALE_STRING[values.budget_accommodation_currency])}.</strong></span>
      case "min--everyone_per_night":
        return <span>The minimum exclusive hire price at this venue is <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(venueInfo.minimum_budget_exclusive_hire_per_night[values.budget_accommodation_currency]).toLocaleString(CURRENCY_LOCALE_STRING[values.budget_accommodation_currency])}</strong> per night.</span>
      case "min--everyone_full_stay":
        return <span>The minimum exclusive hire price at this venue is <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(venueInfo.minimum_budget_exclusive_hire_per_night[values.budget_accommodation_currency]).toLocaleString(CURRENCY_LOCALE_STRING[values.budget_accommodation_currency])}</strong> per night.
          For {numberNights} {numberNights === 1 ? "night" : "nights"}, you'd need a minimum total budget of <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(minimumFullStay).toLocaleString(CURRENCY_LOCALE_STRING[values.budget_accommodation_currency])}.</strong></span>
    }

  } else {
    const minimumPPPN = values.occupancy_type === "sharing_rooms"
      ? venueInfo.minimum_budget_accommodation_pppn?.[values.budget_accommodation_currency]
      : venueInfo.minimum_budget_accommodation_prpn?.[values.budget_accommodation_currency];

    switch (errorCode) {
      case "min--pppn":
        return <span>{roomsPrices} at this venue start at <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(minimumPPPN)}</strong> per person per night.</span>
      case "min--everyone_per_night":
        return <span>A {values.number_people} {roomPerson} booking at this venue starts from <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(minimumPPPN * values.number_people)}</strong> per night</span>
      case "min--everyone_full_stay":
        return <span>A {values.number_people} {roomPerson} booking for {numberNights} {numberNights === 1 ? "night" : "nights"} at this venue starts from <strong>{CURRENCY_SYMBOLS[values.budget_accommodation_currency]}{Math.round(minimumPPPN * values.number_people * numberNights)}</strong></span>
    }

  }

}

const Budget: React.FC<Props> = () => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext<Values>();
  const venueInfo = useVenue();

  const budgetOptionsTouched = touched?.budget_accommodation_amount || touched?.budget_accommodation_currency || touched?.budget_type;

  return (
    <div>
      <div className="flex flex-col items-stretch md:flex-row md:items-start">
        <div className="flex-1 md:max-w-[190px] mb-2 md:mb-0 md:mr-4">
          <CurrencyInput
            amountValue={values["budget_accommodation_amount"]?.toString() || ""}
            currencyValue={values["budget_accommodation_currency"]}
            onChangeAmount={(num) => {
              setFieldValue("budget_accommodation_amount", num)
            }}
            onChangeCurrency={async (currency) => {
              setFieldValue("budget_accommodation_currency", currency).then(() => setFieldTouched("budget_accommodation_currency"));
            }}
            onBlur={() => setFieldTouched("budget_accommodation_amount")}
          />
        </div>
        <div className="flex-1 md:max-w-[200px]">
          <Select options={Object.values(options)} selected={options[values.budget_type]} setSelected={option => {
            setFieldValue("budget_type", option.id)
          }} onBlur={() => setFieldTouched("budget_type")} />
        </div>
      </div>
      <div>
        {(errors?.budget_accommodation_amount && budgetOptionsTouched)
          && (
            <div className="flex items-center mt-2">
              <InformationIcon className="mr-2 w-5" />{budgetTooLowText(errors?.budget_accommodation_amount, values, venueInfo)}
            </div>
          )}
      </div>
    </div>

  );
}

export default Budget;