import React, { useCallback } from "react";
import { LockedVenue } from "../types/Venue.interface";
import LockIcon from "../../../../../../assets/svg/lock-icon.svg";

interface Props {
  venue: LockedVenue;
}

const LockedVenueSlide: React.FC<Props> = ({ venue }) => {
  const openSignUp = useCallback(() => {
    (window as any).openSignUp();
  }, []);
  return (
    <div className="bg-white rounded-xl flex items-stretch shadow-lg">
      <div className="w-[45%] relative z-0">
        <div className="absolute inset-0 z-20 flex flex-col items-center justify-center">
          <LockIcon className="mb-2" width="14" />
          <button
            type="button"
            className="
              ring-1
              ring-white
              text-white
              rounded-lg
              px-2
              py-1
              bg-white
              bg-opacity-20
              hover:bg-opacity-100
              hover:text-black
              transition-colors
              duration-100
              text-xs
            "
            onClick={openSignUp}
          >
            Create account
            <br />
            to view
          </button>
        </div>
        <img
          src={venue.primary_photo_url}
          className="object-cover rounded-l-xl h-full brightness-50"
        />
      </div>
      <div className="flex-1 py-5 px-4">
        <div className="bg-gray-200 h-5 mb-2"></div>
        <div className="bg-gray-200 h-5 mb-2"></div>
        <div className="flex flex-col items-stretch space-y-1">
          <div className="bg-gray-200 h-4 w-[70%]"></div>
          <div className="bg-gray-200 h-4 w-[70%]"></div>
        </div>
      </div>
    </div>
  );
};

export default LockedVenueSlide;
