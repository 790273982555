import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import cx from "classnames";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

interface TextInputWithLabelProps {
  name: string;
  type?: string;
  labelText: string;
  placeholder?: string;
  validationErrorMessage?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  hint?: string;
  required?: boolean;
}

const TextInputWithLabel: React.FC<TextInputWithLabelProps> = ({ name, type = "text", labelText, placeholder, validationErrorMessage, className, hint, required, labelClassName, inputClassName }) => {
  const { errors, touched } = useFormikContext();
  return (
    <div className={className}>
      <label htmlFor={name} className={cx("block text-sm font-medium leading-6 text-gray-900", labelClassName)}>
        {labelText}
        {required && <span className="ml-1">*</span>}
      </label>
      <div className="relative mt-1">
        <Field
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          className={cx(
            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
            inputClassName,
            touched[name] && errors[name] && "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500",
          )}
        />
        {touched[name] && errors[name] && <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>}
      </div>
      {hint && <div className="text-sm text-slate-600 mt-1">{hint}</div>}
      {touched[name] && errors[name] && <div className="text-red-500 text-sm mt-1"><ErrorMessage name={name} /></div>}
    </div>
  )
}

export default TextInputWithLabel;