import React from "react";
import Loading from "../../../../assets/svg/loading-spinner.svg";
import cx from "classnames";

interface Props extends React.PropsWithChildren {
  variant?: string;
  onClick?: () => void;
  loading?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  href?: string;
}

const Button: React.FC<Props> = ({
  variant = "default",
  onClick,
  children,
  loading,
  className,
  type,
  disabled
}) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      className={cx(`bj-button bj-button--${variant} box-border`, className)}
      disabled={disabled}
    >
      <div className="bj-button__bg"></div>
      {loading && (
        <Loading className="relative z-20 w-5 h-5 mr-2.5 animate-spin" />
      )}
      <span className="relative z-20 leading-none">{children}</span>
    </button>
  );
};

export default Button;
