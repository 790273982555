import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ClerkProvider } from "@clerk/clerk-react";
import { getPublishableKey } from "~/config/clerk";
import WithoutContext from "./WithoutContext";

const WithContext: React.FC = () => {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <ClerkProvider publishableKey={getPublishableKey()} appearance={{
      layout: {
        socialButtonsVariant: "blockButton"
      }
    }}>
      <QueryClientProvider client={queryClient}>
        <WithoutContext />
      </QueryClientProvider>
    </ClerkProvider>
  )
}

export default WithContext;

