import React, { useEffect, useState } from "react";
import qs from "qs";
import qsBooleanDecoder from "~/bundles/utils/qsBooleanDecoder";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import Select from "../Shared/Select/Select";
import TextInputWithLabel from "../Shared/TextInputWithLabel";
import Button from "../Button";
import ScrollLock from "~/utils/scroll-lock";

const whereDidYouFindUsOptions = [
  {
    id: "google",
    name: "Google"
  },
  {
    id: "linkedin",
    name: "LinkedIn"
  },
  {
    id: "instagram",
    name: "Instagram"
  },
  {
    id: "twitter",
    name: "Twitter"
  },
  {
    id: "referral",
    name: "Friend, co-worker or recommendation"
  },
  {
    id: "other",
    name: "Other"
  }
]

const accountTypeOptions = [
  {
    id: "company",
    name: "Company looking for a team/leadership/offsite retreat venue"
  },
  {
    id: "venue_owner_team",
    name: "Venue owner/team"
  },
  {
    id: "venue_marketer",
    name: "Venue marketing agency/freelancer"
  },
  {
    id: "freelance_event_planner",
    name: "Freelance event planner"
  },
  {
    id: "event_agency",
    name: "Event agency"
  }
]

const FormBody: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<Values>();
  return (
    <Form>
      <TextInputWithLabel name="company_name" labelText="Your company name" className="mb-4" labelClassName="font-bold text-base mb-2 block" inputClassName="!rounded-full px-5" />
      <label htmlFor="account_type" className="text-base font-bold mb-2 block">I am a...</label>
      <Select isFormik name="account_type" options={accountTypeOptions} selected={accountTypeOptions.find(v => v.id === values.account_type)} setSelected={v => setFieldValue("account_type", v.id)} className="mb-4" />
      <label htmlFor="where_did_you_find_us" className="text-base font-bold mb-2 block">Where did you find us?</label>
      <Select isFormik name="where_did_you_find_us" options={whereDidYouFindUsOptions} selected={whereDidYouFindUsOptions.find(v => v.id === values.where_did_you_find_us)} setSelected={v => setFieldValue("where_did_you_find_us", v.id)} className="mb-4" />
      {
        values.where_did_you_find_us === "google" && <TextInputWithLabel name="google_keywords" labelText="What did you search for in Google? 🔍" className="mb-4" labelClassName="font-bold text-base mb-2 block" inputClassName="!rounded-full px-5" />
      }
      {
        values.where_did_you_find_us === "other" && <TextInputWithLabel name="other_where_did_you_find_us" labelText="Please let us know where you found us..." className="mb-4" labelClassName="font-bold text-base mb-2 block" inputClassName="!rounded-full px-5" />
      }
      <TextInputWithLabel name="referral_code" labelText="Referral code (if you have one)" className="mb-6" labelClassName="font-bold text-base mb-2 block" inputClassName="!rounded-full px-5" />
      <Button type="submit" className="block w-full">Continue &rarr;</Button>
      {/* <pre className="mt-8">
        {JSON.stringify(values, null, 2)}
      </pre> */}
    </Form>
  )
}

interface Values {
  company_name: string;
  where_did_you_find_us?: string;
  account_type?: string;
  google_keywords?: string;
  other_where_did_you_find_us?: string;
  referral_code?: string;
}

const validationSchema = Yup.object().shape({
  company_name: Yup.string().min(1).required("Please enter your company name"),
  where_did_you_find_us: Yup.string().min(1).required("Please select where you found us"),
  account_type: Yup.string().min(1).required("Please let us know who you are")
});

const NewAccountModal: React.FC = () => {
  const isNewAccount = qs.parse(window.location.search.slice(1), { decoder: qsBooleanDecoder })?.new_account;
  const [isModalVisible, _setIsModalVisible] = useState(isNewAccount);

  useEffect(() => {
    isModalVisible ? ScrollLock.enable() : ScrollLock.disable();
  }, [isModalVisible])

  if (!isModalVisible) { return <div></div> }
  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative z-[100] flex min-h-screen items-center justify-center px-4 md:px-0">
        <div className="w-full max-w-md rounded-3xl bg-white p-8 shadow-lg">
          <h2 className="font-heading font-extrabold text-2xl mb-2 text-center">Welcome to Basejam</h2>
          <p className="mb-6 text-center max-w-[32ch] mx-auto">The best company retreat venues in Europe. <strong>Free</strong> to direct message ✉️</p>
          <Formik<Values>
            initialValues={{
              company_name: "",
              where_did_you_find_us: null,
              account_type: null,
              google_keywords: "",
              other_where_did_you_find_us: "",
              referral_code: ""
            }}
            onSubmit={values => { console.log(values) }}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnMount={false}
            validateOnChange={true}
          >
            <FormBody />
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default NewAccountModal;