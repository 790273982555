import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Browse, { Props as BrowseProps } from "./Browse";
import { LayoutProvider } from "./contexts/layoutContext";
import { MapProvider } from "react-map-gl";
import { HydrationProvider } from "react-hydration-provider";
import { SwiperRefProvider } from "./contexts/swiperRefContext";
import { SearchProvider } from "./contexts/searchContext";
import { VenuesProvider } from "./contexts/venuesContext";

const App: React.FC<BrowseProps> = (props) => {
  const [queryClient] = useState(() => new QueryClient());
  const { search_params, initial_venues, initial_locked_venues } = props;
  return (
    <QueryClientProvider client={queryClient}>
      <HydrationProvider>
        <LayoutProvider>
          <MapProvider>
            <SwiperRefProvider>
              <SearchProvider search_params={search_params} update_history={true}>
                <VenuesProvider
                  initial_venues={initial_venues}
                  initial_locked_venues={initial_locked_venues}
                >
                  <Browse {...props} />
                </VenuesProvider>
              </SearchProvider>
            </SwiperRefProvider>
          </MapProvider>
        </LayoutProvider>
      </HydrationProvider>
    </QueryClientProvider>
  );
};

export default App;
