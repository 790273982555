import React from "react";
import { BudgetType, OccupancyType, Values } from "../EnquiryFormValues.interface";
import { useVenue } from "../contexts/venueContext";
import moment from "moment";

export default (): Values => {
  const { last_enquiry, other_requirements_checkboxes } = useVenue();

  const initialValues = {
    booking_dates: [{ start_date: null, end_date: null }],
    number_people: null,
    occupancy_type: "single_occupancy" as OccupancyType,
    budget_accommodation_amount: null,
    budget_accommodation_currency: "GBP",
    budget_type: "pppn" as BudgetType,
    purpose: null,
    company_name: "",
    other_requirements: Object.fromEntries(Object.keys(other_requirements_checkboxes).map(k => [k, false])),
    message_to_venue: ""
  }

  if (!last_enquiry) {
    return initialValues;
  }

  return {
    ...initialValues,
    booking_dates: last_enquiry.booking_dates.map(datePair => ({ start_date: moment(datePair.start_date), end_date: moment(datePair.end_date) })),
    number_people: last_enquiry.number_people,
    occupancy_type: last_enquiry.occupancy_type ? last_enquiry.occupancy_type : "single_occupancy",
    budget_accommodation_amount: last_enquiry.budget_accommodation_cents ? last_enquiry.budget_accommodation_cents / 100 : null,
    budget_accommodation_currency: last_enquiry.budget_accommodation_currency ? last_enquiry.budget_accommodation_currency : "GBP",
    budget_type: last_enquiry.budget_type ? last_enquiry.budget_type : "pppn",
    purpose: last_enquiry.purpose,
    company_name: last_enquiry.company_name,
    other_requirements: {
      ...initialValues.other_requirements,
      ...last_enquiry.other_requirements
    },
    message_to_venue: last_enquiry.message_to_venue
  }
}