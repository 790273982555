import { FormikErrors, useFormikContext } from "formik";
import React, { useCallback, useState } from "react";
import { DateRangePicker } from "react-dates";
import { BookingDate, Values } from "../../EnquiryFormValues.interface";

interface Props {
  index: number;
}


const Picker: React.FC<Props> = ({ index }) => {
  const { setFieldValue, values, setFieldTouched, errors, touched } = useFormikContext<Values>();
  const [focusedInput, setFocusedInput] = useState(null);
  const onDatesChange = useCallback(async ({ startDate, endDate }) => {
    if (startDate) {
      await setFieldValue(`booking_dates.${index}.start_date`, startDate)
      await setFieldTouched(`booking_dates.${index}.start_date`);
    }
    if (endDate) {
      await setFieldValue(`booking_dates.${index}.end_date`, endDate)
      await setFieldTouched(`booking_dates.${index}.end_date`);
    }
  }, [setFieldValue]);
  const onFocusChange = useCallback((_focusedInput) => {
    setFocusedInput(_focusedInput)
  }, [setFocusedInput])
  return (
    <div>
      <DateRangePicker
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={values.booking_dates[index]?.start_date}
        endDate={values.booking_dates[index]?.end_date}
        startDateId="start_date"
        endDateId="end_date"
      />
    </div>
  )
}

export default Picker;