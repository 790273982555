import React, { useCallback, useEffect, useState } from "react";
import CurrencyDropdown, { Currency } from "./CurrencyDropdown";
import { useCookies } from "react-cookie"

interface Props {
  initial_currency_code: string;
}

const currencies: Currency[] = [
  {
    threeLetterSymbol: "GBP",
    emoji: "🇬🇧"
  }, {
    threeLetterSymbol: "EUR",
    emoji: "🇪🇺"
  }, {
    threeLetterSymbol: "USD",
    emoji: "🇺🇸"
  }, {
    threeLetterSymbol: "CHF",
    emoji: "🇨🇭"
  }
]

const CurrencySwitcher: React.FC<Props> = ({ initial_currency_code }) => {
  const [cookies, setCookie] = useCookies(["currency_code"]);

  const currency = currencies.find(c => c.threeLetterSymbol === (cookies.currency_code || initial_currency_code));

  const onChangeCurrency = useCallback((newCurrency) => {
    document.dispatchEvent(new CustomEvent("currencyChanged", { detail: newCurrency }))
    setCookie("currency_code", newCurrency.threeLetterSymbol)
  }, [setCookie])

  return (
    <CurrencyDropdown currencies={currencies} selectedCurrency={currency} onChangeCurrency={onChangeCurrency} />
  )
}

export default CurrencySwitcher;