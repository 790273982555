import React, { useCallback, useState } from "react";
import Picker from "./Picker";
import { FormikErrors, useFormikContext } from "formik";
import { Values } from "../../EnquiryFormValues.interface";
import TrashIcon from "../../../../../../../assets/svg/trash.svg";
import moment from "moment";

moment.locale("en-gb");

interface BookingDateErrors {
  start_date: string;
  end_date: string;
}

const Dates: React.FC = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<Values>();

  const addNewDatePair = useCallback(() => {
    setFieldValue(`booking_dates.${values.booking_dates.length}`, { start_date: null, end_date: null })
  }, [setFieldValue, values])

  const removeDates = (atIndex) => {
    const newBookingDates = values.booking_dates.filter((_datePair, index) => index !== atIndex)
    setFieldValue("booking_dates", newBookingDates);
  }

  return (
    <div>
      <label className="block mb-2 text-sm">Dates</label>
      <div className="enquiry-form-dates space-y-2">
        {values.booking_dates.map((_datePair, index) => (
          <div key={index}>
            <div className="flex flex-wrap gap-4 items-center">
              <div className="flex items-stretch">
                <Picker index={index} />
                {values.booking_dates.length > 1 && <button onClick={() => removeDates(index)} className="flex items-center justify-center px-2"><TrashIcon className="w-3 h-3" /></button>}
              </div>
              {index === (values.booking_dates.length - 1) && (
                <div>
                  <button className="text-sm leading-[22px] bg-bj-purple-light text-bj-purple-dark ring-1 ring-bj-purple-dark px-6 py-2 rounded-full" onClick={addNewDatePair}>Add extra dates</button>
                </div>
              )}
            </div>
            <div className="text-red-500 text-sm mt-1">{
              (errors?.booking_dates && errors.booking_dates[index] as FormikErrors<BookingDateErrors>)?.start_date
              && touched?.booking_dates && touched.booking_dates[index]?.start_date
              && (errors.booking_dates[index] as FormikErrors<BookingDateErrors>)?.start_date
            }</div>
            <div className="text-red-500 text-sm mt-1">{
              (errors?.booking_dates && errors.booking_dates[index] as FormikErrors<BookingDateErrors>)?.end_date
              && touched?.booking_dates && touched.booking_dates[index]?.end_date
              && (errors.booking_dates[index] as FormikErrors<BookingDateErrors>)?.end_date
            }</div>
          </div>
        ))}
      </div>
      <div className="text-red-500 text-sm mt-1">{
        (errors?.booking_dates && typeof errors?.booking_dates === "string")
        && touched?.booking_dates
        && (errors.booking_dates)
      }</div>
    </div>
  )
}

export default Dates;