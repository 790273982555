import React, { useCallback } from "react";

interface Props {
  number: number;
  onChange: (x: number) => void;
  label: string;
}

const BJNumberInput: React.FC<Props> = ({ number, onChange, label }) => {
  const increment = useCallback(() => {
    if (typeof number === "undefined" || number === null) {
      return onChange(1);
    }
    return onChange(Number(number) + 1);
  }, [number, onChange]);
  const decrement = useCallback(() => {
    if (typeof number === "undefined" || number === null) {
      return onChange(1);
    }
    return onChange(Number(number) - 1);
  }, [number, onChange]);
  return (
    <div>
      <label
        htmlFor="number-input"
        className="block text-sm font-medium text-black"
      >
        {label}
      </label>
      <div className="flex flex-row rounded-lg relative bg-transparent mt-1">
        <button
          onClick={decrement}
          type="button"
          disabled={
            typeof number === "undefined" || number === null || number === 0
          }
          className="
            bg-transparent
            text-bj-purple-dark
            border
            border-slate-300
            border-r-0
            hover:bg-slate-50
            rounded-l-full
            pr-3
            pl-4
            cursor-pointer
            disabled:opacity-50
            disabled:cursor-default
          "
        >
          <span className="m-auto text-xl font-thin">−</span>
        </button>
        <input
          name="number-input"
          id="number-input"
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          className="w-24 outline-none focus:outline-none text-center bg-transparent font-semibold text-sm md:text-basecursor-default flex items-center text-bj-purple-dark border-slate-300 appearance-none webkit-appearance-none"
          value={number || ""}
          onChange={(e) => onChange(Number(e.target.value))}
        />
        <button
          onClick={increment}
          type="button"
          className="
            bg-transparent
            text-bj-purple-dark
            border
            border-slate-300
            border-l-0
            hover:bg-slate-50
            rounded-r-full
            pr-4
            pl-3
            cursor-pointer
          "
        >
          <span className="m-auto text-xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
};

export default BJNumberInput;
