import React from "react";
import { useVenues } from "../contexts/venuesContext";
import { useSearch } from "../contexts/searchContext";
import VenueCell from "../venue-cells/VenueCell";
import LockedVenueCell from "../venue-cells/LockedVenueCell";
import MagnifyingGlass from "../../../../../../assets/svg/magnifying-glass.svg";
import CrossIcon from "../../../../../../assets/svg/cross.svg";
import VenueCellLoading from "../venue-cells/VenueCellLoading";

const VenueList: React.FC = () => {
  const { venues, locked_venues, venueRefs, isLoading } = useVenues();
  const { clearSearch } = useSearch();

  return isLoading ? (
    <>
      <VenueCellLoading />
      <VenueCellLoading />
      <VenueCellLoading />
    </>
  ) : (
    <>
      {venues.map((venue) => (
        <VenueCell
          venue={venue}
          key={venue.id}
          ref={(el) => (venueRefs.current[venue.id] = el)}
        />
      ))}
      {locked_venues.map((venue) => (
        <LockedVenueCell
          key={venue.id}
          venue={venue}
          ref={(el) => (venueRefs.current[venue.id] = el)}
        />
      ))}
      {venues?.length === 0 && locked_venues?.length === 0 && (
        <div>
          <MagnifyingGlass className="mb-6 w-[86px]" />
          <h4 className="font-heading mb-2">No results here (yet!)</h4>
          <p className="mb-4 max-w-lg">
            We're continually growing our database, but we can't find anything
            for your criteria right now. In the meantime, try editing or
            clearing the filters.
          </p>
          <button
            type="button"
            onClick={clearSearch}
            className="text-gray-900 flex items-center border border-gray-600 px-3 py-2 rounded-md hover:bg-gray-100 transition-colors duration-200"
          >
            <CrossIcon className="mr-1 w-5 h-5" />
            Clear filters
          </button>
        </div>
      )}
    </>
  );
};

export default VenueList;
