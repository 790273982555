import React from "react";
import Skeleton from "react-loading-skeleton";

const VenueCellLoading: React.FC = () => (
  <div className="md:flex md:space-x-7">
    <div className="block mb-4 md:mb-0 md:w-1/2">
      <Skeleton className="h-full" />
    </div>
    <div className="flex-1 pr-8 md:pr-0">
      <h2 className="font-heading text-4xl mb-3">
        <Skeleton />
      </h2>
      <p className="text-gray-600 text-sm mb-3">
        <Skeleton count={3} />
      </p>
      <div className="flex space-x-4 mb-2">
        <div className="text-gray-600 text-sm mb-3 flex items-center">
          <Skeleton />
        </div>
        <div className="text-gray-600 text-sm mb-3 flex items-center">
          <Skeleton />
        </div>
      </div>
    </div>
  </div>
);

export default VenueCellLoading;
