import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import HeaderAuth from "../HeaderAuth/HeaderAuth";
import { ClerkLoaded } from "@clerk/clerk-react";
import differenceInMinutes from "date-fns/differenceInMinutes";
import NewAccountModal from "../NewAccountModal/NewAccountModal";

const ClerkListeners: React.FC = () => {
  useEffect(() => {
    const _signOut = window.Clerk.signOut;
    window.Clerk.signOut = async (callbackOrOptions?, options?) => {
      await _signOut(callbackOrOptions, options);
      window.posthog.reset();
    };

    window.Clerk.addListener((resources) => {
      // console.log("Clerk listener, resources", resources);
      // console.log("Clerk listener, resources.user", resources.user);
      // console.log("Clerk listener, resources.client.signUp", resources.client.signUp);
      // console.log("Clerk listener, resources.client.signUp.id", resources.client.signUp.id, resources.client.signUp.emailAddress);
      // console.log("Clerk listener, resources.user", resources.user?.createdAt);
      if (resources.user) {
        if (differenceInMinutes(new Date(), resources.user.createdAt) <= 5) {
          // New sign up

          const personalDomains = [
            "@gmail",
            "@hotmail",
            "@gmx",
            "@yahoo",
            "@protonmail",
            "@aol.com",
            "@outlook.com"
          ];

          // If it's a personal domain, do not send event
          // This event is used for Google Ads conversion goals to prioritise people signing up with company email addresses
          if (personalDomains.some(domain => resources.user.primaryEmailAddress.emailAddress.includes(domain))) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'sign_up_personal_email',
              user_id: resources.user.id,
              email: resources.user.primaryEmailAddress.emailAddress
            });
          } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'sign_up_company_email',
              user_id: resources.user.id,
              email: resources.user.primaryEmailAddress.emailAddress
            });
            // Wait for FBQ
            let retryCount = 0; function waitForFBQ(t) { window.fbq && window.fbq.version ? t() : (retryCount += 1, retryCount < 10 && setTimeout(waitForFBQ.bind(null, t), 1e3)) }
            waitForFBQ(function () { window.fbq('track', 'Lead') })
          }
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'user_id': resources.user.id
          })
        }
        window.posthog.identify(resources.user.id, {
          primary_email_address:
            resources.user.primaryEmailAddress.emailAddress,
          email_addresses: resources.user.emailAddresses.map(
            (e) => e.emailAddress
          ),
          first_name: resources.user.firstName,
          last_name: resources.user.lastName,
        });
      }
    });

    window["openSignIn"] = () => {
      window.Clerk.openSignIn({
        redirectUrl: `/auth-redirect?url=${window.btoa(window.location.href)}`
      });
    };
    window["openSignUp"] = () => {
      window.Clerk.openSignUp({
        redirectUrl: `/auth-redirect?url=${window.btoa(window.location.href)}`,
      });
    };

    window["signOut"] = async () => {
      await window.Clerk.signOut();
      window.location.href = window.location.href;
    }

  }, [])
  return (
    <></>
  )
}


const createPortalIfExists = (component: ReactNode, domNode?: HTMLElement) => {
  if (domNode !== null) {
    return createPortal(component, domNode);
  } else {
    return <></>
  }
}

const WithoutContext: React.FC = () => {
  return (
    <>
      <ClerkLoaded>
        <ClerkListeners />
      </ClerkLoaded>
      {createPortalIfExists(<HeaderAuth />, document.querySelector("[data-default-component=HeaderAuth]"))}
      {createPortalIfExists(<NewAccountModal />, document.querySelector("[data-default-component=NewAccountModal]"))}
    </>
  )
}

export default WithoutContext;