import React from "react";
import { Field } from "formik";

interface Props { }

const MessageToVenue: React.FC<Props> = () => {
  return (
    <div>
      <Field as="textarea" name="message_to_venue" className="rounded-xl border-0 ring-1 ring-gray-300 ring-inset focus:ring-2 focus:ring-inset focus:ring-bj-purple-dark px-4 py-3 w-full min-h-[100px]" />
    </div>
  )
}

export default MessageToVenue;