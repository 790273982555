import React, { useState, useCallback, useContext, useEffect, ReactNode, Dispatch, SetStateAction } from "react";
import { Search } from "../types/Search.interface";
import { useDebounce } from "usehooks-ts";
import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import qs from "qs";
import { useMap } from "react-map-gl";


interface Props {
  search_params: Search;
  update_history?: boolean;
  children: ReactNode;
}

export const DEFAULT_SEARCH = {
  location: {
    id: "initial_europe",
    name: "Anywhere in Europe 🌍",
    bounding_box: {
      ne: {
        lat: 62.548109,
        lng: 37.196274
      },
      sw: {
        lat: 27.130035,
        lng: -19.492493
      },
    },
  },
  accommodation_style_ids: [],
  location_type_ids: [],
  venue_amenity_ids: [],
  retreat_style_ids: [],
  number_rooms: null
};

const SearchContext = React.createContext(null);

const booleanDecoder = (value, decoder) => {
  let keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined,
  };
  if (value in keywords) {
    return keywords[value];
  }
  return decoder(value);
};

export const SearchProvider: React.FC<Props> = ({
  search_params,
  update_history,
  children,
}) => {
  const [search, _setSearch] = useState<Search>(search_params);
  const setSearch = useCallback((s) => {
    _setSearch({ ...s, is_pseo: false, pseo_path: null });
  }, []);
  const { venuesMap } = useMap();

  const debouncedSearch = useDebounce(search, 500);

  const searchMutation = useMutation<Search, AxiosError, Search>((attrs) => {
    console.log("searchMutation", attrs);
    let search_qs;
    if (attrs.is_pseo === true) {
      search_qs = `/${attrs.pseo_path}`;
    } else {
      search_qs = `?${qs.stringify(attrs, {
        arrayFormat: "brackets",
      })}`;
    }
    return axios.patch("/search", { search: { search_qs } });
  });

  useEffect(() => {
    if (update_history) {
      if (search.is_pseo === true) {
        // window.history.replaceState({}, "", `/venues/${search.page_slug}`);
        // (window as any).Turbo.navigator.history.replace(
        //   new URL(window.location.href)
        // );
        return;
      } else {
        // Merge existing query string with new filters
        const existingQS = qs.parse(window.location.search.slice(1), {
          decoder: booleanDecoder,
        });
        const queryString = qs.stringify({ ...existingQS, ...search }, { arrayFormat: "brackets" });
        window.history.replaceState(
          {},
          "",
          `/venues?${queryString}`
        );
        (window as any).Turbo.navigator.history.replace(
          new URL(window.location.href)
        );
        Array.from(document.querySelectorAll("[data-pseo]")).forEach(element => element.remove());
      }
      searchMutation.mutate(search);
    }
  }, [search]);

  const clearSearch = useCallback(() => {
    setSearch(DEFAULT_SEARCH);
    // setBounds(DEFAULT_SEARCH);
  }, []);

  const setMapBounds = ({ location }) => {
    if (!venuesMap) {
      console.log("!!!!!!!! setMapBounds: no map");
    }
    venuesMap?.fitBounds([location.bounding_box.sw, location.bounding_box.ne], {
      padding: 0,
    });
  };

  useEffect(() => {
    setMapBounds({ location: search.location });
  }, [venuesMap, search.location]);

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        _setSearch,
        debouncedSearch,
        clearSearch,
        setMapBounds,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
