import Clerk from '@clerk/clerk-js';

export const getPublishableKey = () => {
  if (window.location.hostname === "localhost") {
    return "pk_test_dGlkeS1tYW1tYWwtMzguY2xlcmsuYWNjb3VudHMuZGV2JA";
  } else if (window.location.hostname === "basejam.dev") {
    return "pk_live_Y2xlcmsuYmFzZWphbS5kZXYk";
  } else {
    return "pk_live_Y2xlcmsuYmFzZWphbS5jb20k";
  }
}

export const initClerk = () => {
  return new Clerk(getPublishableKey());
}

