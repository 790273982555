import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import EnquiryForm from "./EnquiryForm";
import { VenueProvider } from "./contexts/venueContext";
import { ClerkProvider } from '@clerk/clerk-react';
import { getPublishableKey } from "../../../../config/clerk";
import DefaultComponentsWithoutContext from "../RenderDefaultComponents/WithoutContext";

import 'react-dates/initialize';

const App: React.FC<any> = (props) => {
  const [queryClient] = useState(() => new QueryClient());
  // Use window.clerk if it exists
  // Otherwise there is a conflict of ClerkProviders from the plain JS version of Clerk used for login
  return (
    <ClerkProvider publishableKey={getPublishableKey()}>
      <QueryClientProvider client={queryClient}>
        <VenueProvider {...props}>
          <DefaultComponentsWithoutContext />
          <EnquiryForm />
        </VenueProvider>
      </QueryClientProvider>
    </ClerkProvider>
  );
};

export default App;
