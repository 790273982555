import React, { RefObject, useCallback, useEffect, useLayoutEffect } from "react";
import { useLayout } from "../contexts/layoutContext";
import { useIsClient, useMediaQuery } from "usehooks-ts";
import { MapRef, useMap } from "react-map-gl";
import VenuesMap from "./VenuesMap";
import cx from "classnames";
import { useSearch } from "../contexts/searchContext";
import VenuesSwiper from "./VenuesSwiper";

const setSizes = (mapContainerDiv: RefObject<HTMLDivElement>, venuesListMapSplitDiv: RefObject<HTMLDivElement>, isMobileMapView: boolean, filtersDiv: RefObject<HTMLDivElement>, venuesMap: MapRef) => {
  const isDesktop = window.innerWidth >= 768;
  if (!mapContainerDiv.current || !venuesListMapSplitDiv.current) {
    return;
  }
  if (!isDesktop) {
    venuesListMapSplitDiv.current.style.paddingTop = "0px";
    mapContainerDiv.current.style.top = "0px";
    mapContainerDiv.current.style.height = "0px";
  }
  if (isMobileMapView) {
    const mapTop = mapContainerDiv.current.getBoundingClientRect().top;
    let desiredHeight = window.innerHeight - mapTop;

    if (desiredHeight < 500) {
      desiredHeight = 500;
    }

    mapContainerDiv.current.style.height = `${desiredHeight}px`;
  }
  if (isDesktop) {
    const header = document.querySelector("#header-announcement");
    const headerHeight = header?.getBoundingClientRect().height || 0;
    const filtersHeight = filtersDiv.current?.getBoundingClientRect().height || 0;
    mapContainerDiv.current.style.top = `${headerHeight + filtersHeight}px`;
    mapContainerDiv.current.style.height = `${window.innerHeight - (headerHeight + filtersHeight)
      }px`;
  }
  // venuesMap.resize only works after content is painted, so call with setTimeout
  setTimeout(() => venuesMap?.resize(), 1)
}

const MapContainer = () => {
  const {
    mapContainerDiv,
    venuesListMapSplitDiv,
    isMobileMapView,
    filtersDiv
  } = useLayout();

  const { setSearch, search } = useSearch();

  const { venuesMap } = useMap();

  const isDesktopMQ = useMediaQuery("(min-width: 768px)");
  const isClient = useIsClient();

  useLayoutEffect(() => {
    setSizes(mapContainerDiv, venuesListMapSplitDiv, isMobileMapView, filtersDiv, venuesMap);
    window.onresize = () => setSizes(mapContainerDiv, venuesListMapSplitDiv, isMobileMapView, filtersDiv, venuesMap);
    return () => window.onresize = null;
  }, [mapContainerDiv, venuesListMapSplitDiv, isMobileMapView, filtersDiv, venuesMap])

  const searchThisArea = useCallback(() => {
    const center = venuesMap.getCenter();
    const bounds = venuesMap.getBounds();

    // Location hash for setting id on query cache
    const locationHash = `${center.lat}:${center.lng}:${bounds.getSouthWest()}:${bounds.getNorthEast()}`;

    setSearch({
      ...search,
      location: {
        id: `map_area:${locationHash}`,
        name: "(map area)",
        lat: center.lat,
        lng: center.lng,
        bounding_box: {
          sw: {
            lng: bounds.getSouthWest().lng,
            lat: bounds.getSouthWest().lat,
          },
          ne: {
            lng: bounds.getNorthEast().lng,
            lat: bounds.getNorthEast().lat,
          },
        },
      },
    });
  }, [search, venuesMap])

  // Using data-client-only here so that this is removed by my edits to the react_ujs code
  // See react_ujs/index.js line 107 onwards for explanation
  return (
    <div
      data-client-only
      ref={mapContainerDiv}
      className={cx(
        isClient && (isDesktopMQ || (!isDesktopMQ && isMobileMapView))
          ? "block"
          : "hidden",
        "flex-1 relative md:sticky"
      )}
    >
      <VenuesMap />
      <VenuesSwiper />
      <div className="absolute top-4 inset-x-0 flex justify-center">
        <button
          type="button"
          onClick={searchThisArea}
          className="inline-block bg-white rounded-full px-4 py-2 shadow-md transition-colors hover:bg-slate-100 relative z-10"
        >
          Search this area
        </button>
      </div>
    </div>

  )

}

export default MapContainer;