import React, { useContext, ReactNode, useState } from "react";

const SwiperRefContext = React.createContext(null);

export const SwiperRefProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [swiper, setSwiper] = useState(null);
  return (
    <SwiperRefContext.Provider value={{ swiper, setSwiper }}>
      {children}
    </SwiperRefContext.Provider>
  );
};

export const useSwiperRef = () => useContext(SwiperRefContext);
