import React from "react";
import cx from "classnames";

interface Option {
  id: string;
  name: string;
  iconName: string;
}

interface Props {
  options: Option[];
  selectedOptionId: string;
  onClickOption: (option: Option) => void;
}

const RadioButtonGroup: React.FC<Props> = ({ options, selectedOptionId, onClickOption }) => {
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      {options.map((option, index) => (
        <button
          key={option.id}
          onClick={() => onClickOption(option)}
          type="button"
          className={cx(
            "inline-flex items-center px-4 py-2 text-sm border border-gray-300 rounded-s-lg hover:bg-bj-purple-light hover:text-bj-purple-dark",
            option.id === selectedOptionId ? "bg-bj-purple-light text-bj-purple-dark border-bj-purple-dark" : "bg-white text-gray-800 border-gray-300",
            index === 0 && "rounded-l-full pl-5",
            index === options.length - 1 && "rounded-r-full pr-5"
          )}
        >
          <svg className="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <span>{option.name}</span>
        </button>
      ))}
    </div>
  )
}

export default RadioButtonGroup;