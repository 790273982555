import React, { ReactNode, useContext, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSearch } from "./searchContext";
import hash from "object-hash";
import axios, { AxiosError } from "axios";
import { LockedVenue, Venue } from "../types/Venue.interface";
import qs from "qs";

const VenuesContext = React.createContext(null);

interface VenuesResponse {
  venues: Venue[];
  locked_venues: LockedVenue[];
}

interface Props {
  initial_venues: Venue[];
  initial_locked_venues: LockedVenue[];
  children: ReactNode;
}

export const VenuesProvider: React.FC<Props> = ({
  initial_venues,
  initial_locked_venues,
  children,
}) => {
  const { debouncedSearch } = useSearch();

  const {
    error,
    data: venuesResponse,
    isLoading,
  } = useQuery<VenuesResponse, AxiosError>({
    queryKey: [`venues:${hash(debouncedSearch)}`],
    queryFn: () => {
      return axios
        .get("/venues", {
          params: { ...debouncedSearch },
          headers: {
            Accept: "application/json",
          },
          paramsSerializer: {
            serialize: (params) => {
              return qs.stringify(params, { arrayFormat: "brackets" });
            },
          },
        })
        .then((response) => response.data);
    },
    initialData: {
      venues: initial_venues,
      locked_venues: initial_locked_venues,
    },
  });

  const { venues, locked_venues } = venuesResponse || {
    venues: [],
    locked_venues: [],
  };

  const venueRefs = useRef({});

  return (
    <VenuesContext.Provider
      value={{ venues, locked_venues, isLoading, error, venueRefs }}
    >
      {children}
    </VenuesContext.Provider>
  );
};

export const useVenues = () => useContext(VenuesContext);
