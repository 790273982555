import React from "react";
import Marquee from "react-fast-marquee";
import VenueReelCell, { ReelVenue } from "./VenueReelCell";

interface Props {
  venues: ReelVenue[]
}

const VenueReel: React.FC<Props> = ({ venues }) => {
  console.log("venues", venues);
  return (
    <div>
      <Marquee autoFill pauseOnHover>
        {venues.map(venue => <VenueReelCell key={venue.id} venue={venue} />)}
      </Marquee>
    </div>
  )
}

export default VenueReel;