import * as Yup from "yup";
import { options as purposeOptions } from "./sections/PurposeOfRetreat/PurposeOfRetreat";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import Props from "./Props.interface";

export default ({ min_capacity, max_capacity, total_number_bedrooms, total_number_beds, minimum_budget_accommodation_prpn, minimum_budget_accommodation_pppn, minimum_budget_exclusive_hire_per_night, is_instant_connect, min_number_nights, exclusive_hire_only }: Props) => {
  const bookingDatesSchema = Yup.object().shape({
    start_date: Yup.string().required("Please enter a start date."),
    end_date: Yup.string().required("Please enter an end date.")
  })

  const datesRoomsSchema = Yup.object().shape({
    booking_dates: Yup.array(bookingDatesSchema)
      .transform(datePairs => datePairs.filter(datePair => datePair.start_date || datePair.end_date))
      .min(1, "You must enter at least one set of dates.")
      .test("test-name", `This venue has a minimum stay of ${min_number_nights} nights.`, function (datePairs) {
        if (!min_number_nights) { return true }
        const datePairsLessThanMinNights = datePairs.filter(datePair => {
          const numberNights = differenceInCalendarDays(new Date(datePair.end_date), new Date(datePair.start_date))
          console.log("number nights", numberNights);
          return Math.abs(numberNights) < min_number_nights
        });
        console.log(datePairsLessThanMinNights);
        return datePairsLessThanMinNights.length > 0 ? false : true
      }),
    number_people:
      (total_number_bedrooms && total_number_beds)
        ? Yup
          .number()
          .required("Please enter a number of people.")
          .min(min_capacity, `This venue accepts bookings for ${min_capacity} or more people.`)
          .when("occupancy_type", {
            is: "single_occupancy",
            then: (schema) => schema.max(total_number_bedrooms, `This venue has a ${total_number_bedrooms} bedrooms. Have you considered room sharing?`),
            otherwise: (schema) => schema.max(total_number_beds, `This venue has ${total_number_beds} beds when sharing rooms.`)
          })
        : Yup
          .number()
          .required("Please enter a number of people.")
          .min(min_capacity, `This venue accepts bookings for ${min_capacity} or more people.`)
          .max(max_capacity, `This venue has a max capacity of ${max_capacity} people.`)
  });

  const topLevelSchema = Yup.lazy(values => Yup.object().concat(datesRoomsSchema).shape({
    purpose: Yup.string().oneOf(Object.keys(purposeOptions), "Please choose a purpose for your company retreat.").required("Please choose a purpose for your company retreat."),
    company_name: Yup.string().required("Please enter your company name."),
    budget_accommodation_amount: is_instant_connect ? Yup
      .number()
      .required("Please enter a budget")
      .transform((budget_accommodation_amount) => {
        const startDateISO = values.booking_dates[0].start_date;
        const endDateISO = values.booking_dates[0].end_date;
        const startDate = new Date(startDateISO);
        const endDate = new Date(endDateISO);
        const numberNights = differenceInCalendarDays(endDate, startDate)

        if (exclusive_hire_only) {
          if (values.budget_type === "pppn") {
            return budget_accommodation_amount * values.number_people;
          } else if (values.budget_type === "everyone_per_night") {
            return budget_accommodation_amount;
          } else if (values.budget_type === "everyone_full_stay") {
            return budget_accommodation_amount / numberNights;
          } else {
            return budget_accommodation_amount;
          }
        } else {
          if (values.budget_type === "pppn") {
            return budget_accommodation_amount;
          } else if (values.budget_type === "everyone_per_night") {
            return budget_accommodation_amount / values.number_people;
          } else if (values.budget_type === "everyone_full_stay") {
            return budget_accommodation_amount / values.number_people / numberNights;
          } else {
            return budget_accommodation_amount;
          }
        }

      })
      .when([
        "occupancy_type",
        "budget_type",
        "budget_accommodation_currency"
      ], ([
        occupancy_type,
        budget_type,
        budget_accommodation_currency
      ], schema) => {

        let minimumBudgetForSelectedOptions;
        if (exclusive_hire_only === true) {
          minimumBudgetForSelectedOptions = minimum_budget_exclusive_hire_per_night[budget_accommodation_currency]
        } else {
          minimumBudgetForSelectedOptions = occupancy_type === "sharing_rooms"
            ? minimum_budget_accommodation_pppn[budget_accommodation_currency]
            : minimum_budget_accommodation_prpn[budget_accommodation_currency];
        }


        if (budget_type === "pppn") {
          return schema.min(minimumBudgetForSelectedOptions, `min--pppn`);
        } else if (budget_type === "everyone_per_night") {
          return schema.min(minimumBudgetForSelectedOptions, `min--everyone_per_night`);
        } else if (budget_type === "everyone_full_stay") {
          return schema.min(minimumBudgetForSelectedOptions, `min--everyone_full_stay`);
        } else {
          return schema
        }
      }) : Yup.number().nullable()
  }));

  return {
    bookingDatesSchema,
    datesRoomsSchema,
    topLevelSchema
  }
};