import React, { useCallback, useRef, useState } from "react";
import { DEFAULT_SEARCH, SearchProvider, useSearch } from "./BrowseV2/contexts/searchContext";
import Button from "./Button";
import qs from "qs";
import BJNumberInput from "./Shared/BJNumberInput/BJNumberInput";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import CountrySelect from "./BrowseV2/shared/CountrySelect";
import { Country } from "./BrowseV2/types/Country.interface";

interface Props {
  countries: Country[];
}

const SearchForm: React.FC<Props> = ({
  countries
}) => {
  const { setSearch, _setSearch, search } = useSearch();
  const [loading, setLoading] = useState(false);
  const searchFormRef = useRef<HTMLDivElement>(null);

  const goToSearch = useCallback(() => {
    setLoading(true);
    (window as any).Turbo.visit(`/venues?${qs.stringify({ ...search }, { arrayFormat: "brackets" })}`);
  }, [setLoading, search]);

  return (
    <div ref={searchFormRef}>
      <div className="flex flex-col md:flex-row justify-start md:items-stretch gap-4 px-0 mb-4">
        <div className="w-full md:w-[180px] lg:w-[280px]" suppressHydrationWarning>
          <CountrySelect
            label="Country"
            selectedLocation={search.location}
            onChange={(location: any) => {
              setSearch({ ...search, location });
            }}
            countries={countries}
          />
        </div>
        {/* <div className="w-full md:w-[172px]">
          <TagSelect
            label="Accommodation style"
            options={accommodation_styles}
            search={search}
            searchKey="accommodation_style_ids"
            setSearch={setSearch}
          />
        </div> */}
        {/* <div className="w-full md:w-[172px]">
          <TagSelect
            label="Location type"
            options={location_types}
            search={search}
            searchKey="location_type_ids"
            setSearch={setSearch}
            showAnything={true}
          />
        </div> */}
        <div className="w-full md:w-[172px]">
          <BJNumberInput
            label="Number of people"
            number={search.number_rooms}
            onChange={(num) => setSearch({ ...search, number_rooms: num })}
          />
        </div>
        <div className="pt-[24px] flex-1">
          <Button
            className="text-base w-full py-0 h-[38px]"
            onClick={goToSearch}
            loading={loading}
          >
            Browse venues &rarr;
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className="flex items-center mr-2 md:mr-4"><CheckCircleIcon className="w-4 h-4 text-gray-500 mr-1" /><span>No booking fees</span></div>
        <div className="flex items-center"><CheckCircleIcon className="w-4 h-4 text-gray-500 mr-1" /><span>Message instantly</span></div>
      </div>
    </div>
  );
};

const SearchFormApp = (props: Props) => {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <QueryClientProvider client={queryClient}>
      <SearchProvider search_params={DEFAULT_SEARCH}>
        <SearchForm {...props} />
      </SearchProvider>
    </QueryClientProvider>
  )

}

export default SearchFormApp;
