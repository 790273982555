import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiperRef } from "../contexts/swiperRefContext";
import { useVenues } from "../contexts/venuesContext";
import VenueSlide from "../venue-cells/VenueSlide";
import LockedVenueSlide from "../venue-cells/LockedVenueSlide";

interface Props { }

const VenuesSwiper: React.FC<Props> = () => {
  const { swiper, setSwiper } = useSwiperRef()
  const { venues, locked_venues } = useVenues();
  return (
    <div className="md:hidden absolute left-0 right-0 bottom-0">
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={1.2}
        spaceBetween={20}
        slidesOffsetBefore={20}
        slidesOffsetAfter={20}
      >
        {venues && venues.length > 0
          && venues.map((venue) => {
            return (
              <SwiperSlide key={venue.id}>
                <div className="pb-10">
                  <VenueSlide venue={venue} />
                </div>
              </SwiperSlide>
            );
          })
        }
        {locked_venues && locked_venues.length > 0
          && locked_venues.map((venue) => {
            return (
              <SwiperSlide key={venue.id}>
                <div className="pb-10">
                  <LockedVenueSlide venue={venue} />
                </div>
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </div>

  )
}

export default VenuesSwiper;